import React, { ReactElement } from 'react';

export const Preloader = (): ReactElement => {
  return (
    <div className="preloader">
      <div className="preloader-container">
        <div className="preloader-image">
          {/* <img src="img/icon/preloader.svg" alt="" /> */}
          <img src="playnity-head.png" alt="Playnity loader" />
        </div>
      </div>
    </div>
  );
};

export default Preloader;
