import classNames from 'classnames';
import { AllocationPoolItemData } from 'models';
import React from 'react';

interface AllocationPoolItemHeaderProps {
  allocationPoolItemData: AllocationPoolItemData;
  isExpanded: boolean;
}

export const AllocationPoolItemHeader: React.FC<
  AllocationPoolItemHeaderProps
> = ({ allocationPoolItemData, isExpanded }) => {
  return (
    <div className="staking-pool-item-header">
      <div className="name">
        {allocationPoolItemData.addressCanAllocate && (
          <i
            className={classNames('accordion-icon fas fa-chevron-down', {
              rotated: isExpanded,
            })}
          ></i>
        )}
        {allocationPoolItemData.poolItemName}
        {!allocationPoolItemData.addressCanAllocate && (
          <div> Not available for your wallet address</div>
        )}
      </div>
    </div>
  );
};
