import { bscConfig, terraConfig } from 'config';
import { ethConfig } from 'config/eth/config-eth';
import { AppConfig } from 'models';

export enum AppChain {
  Bsc = 'BSC',
  Terra = 'TERRA',
  Ethereum = 'ETH',
}

export enum AppChainGroup {
  Evm = 'EVM',
}

export const evmChains: AppChain[] = [AppChain.Bsc, AppChain.Ethereum];

// TODO: Use isChain - circular dependecy - separate MR
export const playnityConfig: AppConfig = (() => {
  switch (process.env.REACT_APP_CHAIN) {
    case AppChain.Bsc:
      return bscConfig;
    case AppChain.Terra:
      return terraConfig;
    case AppChain.Ethereum:
      return ethConfig;
  }
})();
