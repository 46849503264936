import BigNumber from 'bignumber.js';
/* eslint-disable react-hooks/rules-of-hooks */
import { ButtonWithSpinner, DataItem, Message } from 'components';
import {
  AppChain,
  getTerraVestingPoolData,
  isChain,
  MessageText,
  MessageType,
  roundNumber,
  vestingClaim,
} from 'helpers';
import { PairData, TokenData, TokenSymbol, VestingPoolData } from 'models';
import React, { useEffect, useReducer } from 'react';
import { Card, ProgressBar, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBalance,
  getTokenPrice,
  getTokensInLpPair,
  selectConnectedAddress,
  selectStableTokenPrices,
  selectTerra,
} from 'store';

import { useConnectedWallet } from '@starterra/starterra-tool-dapp';
import { LCDClient } from '@terra-money/terra.js';
import { ConnectedWallet } from '@terra-money/wallet-provider';

import {
  initialVestingPoolState,
  VestingPoolActions,
  vestingPoolReducer,
} from './store/reducer';

interface VestingPoolProps {
  tokenData: TokenData;
  LPTokenData: TokenData;
  stableTokenData: TokenData;
  LPPairData: PairData;
  vestingPoolData: VestingPoolData;
}

const VestingPool: React.FC<VestingPoolProps> = ({
  tokenData,
  LPTokenData,
  stableTokenData,
  LPPairData,
  vestingPoolData,
}) => {
  const [state, dispatch] = useReducer(
    vestingPoolReducer,
    initialVestingPoolState
  );
  const dispatchApp = useDispatch();
  const connectedAddress = useSelector(selectConnectedAddress);
  const stableTokenPrices = useSelector(selectStableTokenPrices);
  // Terra specific fields
  let terra: LCDClient;
  let terraConnectedWallet: ConnectedWallet;

  if (isChain(AppChain.Terra)) {
    terra = useSelector(selectTerra);
    terraConnectedWallet = useConnectedWallet();
  }

  useEffect(() => {
    getVestingPoolDetailsData();
    dispatchApp(getBalance(tokenData, connectedAddress, terra));
    dispatchApp(
      getTokenPrice(
        stableTokenPrices,
        tokenData,
        stableTokenData,
        LPPairData,
        terra
      )
    );
    dispatchApp(getTokensInLpPair(tokenData, LPTokenData, LPPairData, terra));
  }, []);

  useEffect(() => {
    if (state.loading === false) {
      getVestingPoolDetailsData();
      dispatchApp(getBalance(tokenData, connectedAddress, terra));
      dispatchApp(
        getTokenPrice(
          stableTokenPrices,
          tokenData,
          stableTokenData,
          LPPairData,
          terra
        )
      );
      dispatchApp(getTokensInLpPair(tokenData, LPTokenData, LPPairData, terra));
    }
  }, [state.loading]);

  const getVestingPoolDetailsData = async () => {
    try {
      let payload;

      // Vesting available only on terra
      switch (process.env.REACT_APP_CHAIN) {
        case AppChain.Terra:
          payload = await getTerraVestingPoolData(
            vestingPoolData,
            connectedAddress,
            tokenData,
            terra
          );
          break;

        default:
          console.error('Unsupported chain');
      }

      dispatch({
        type: VestingPoolActions.SetVesting,
        payload,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const amountLeftBN = new BigNumber(state.vesting?.amountLeft);
  const amountClaimableBN = new BigNumber(state.vesting?.amountClaimable);
  const amountAllocatedBN = new BigNumber(state.vesting?.amountAllocated);
  const progress = amountLeftBN.isGreaterThan(0)
    ? +amountAllocatedBN.minus(amountLeftBN).div(amountAllocatedBN).times(100)
    : 100;

  const onClaim = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    dispatch({ type: VestingPoolActions.SetLoading, payload: true });
    dispatch({ type: VestingPoolActions.SetErrorMessage, payload: '' });

    await vestingClaim(
      vestingPoolData,
      connectedAddress,
      tokenData,
      state.vesting.amountClaimable,
      terraConnectedWallet,
      terra
    ).catch((err) => {
      dispatch({
        type: VestingPoolActions.SetErrorMessage,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        payload: (err as any).message,
      });
    });

    dispatch({ type: VestingPoolActions.SetLoading, payload: false });
  };

  return (
    <Card body className="vesting-pool">
      {state.vesting && (
        <>
          <div className="vesting-pool-name">{vestingPoolData.poolType}</div>
          <div className="vesting-pool-details">
            <DataItem label="Total Allocated">
              {roundNumber(state.vesting.amountAllocated)} {TokenSymbol.PLY}
            </DataItem>
            <DataItem label="Claimable / Left">
              {amountLeftBN.isGreaterThan(0) ? (
                <>
                  {roundNumber(state.vesting.amountClaimable)} /{' '}
                  {roundNumber(state.vesting.amountLeft)} {TokenSymbol.PLY}
                </>
              ) : (
                <span>Everything claimed</span>
              )}
            </DataItem>
            {amountLeftBN.isGreaterThan(0) && (
              <ButtonWithSpinner
                text="Claim"
                classes="btn btn-style-three vesting-pool-button"
                onClick={onClaim}
                disabled={amountClaimableBN.isEqualTo(0) || state.loading}
                loading={state.loading}
              />
            )}
          </div>
          <div className="vesting-pool-progress">
            <ProgressBar animated striped variant="info" now={progress} />
          </div>
          {amountClaimableBN.isEqualTo(0) && amountLeftBN.isGreaterThan(0) && (
            <Message
              messageType={MessageType.Primary}
              descriptionText={MessageText.NothingToClaim}
            />
          )}
          <Message
            messageType={MessageType.Primary}
            descriptionText={MessageText.Fees}
          />
        </>
      )}
      {!state.vesting && (
        <div className="spinner-wrapper">
          <Spinner animation="border" role="status" variant="light" />
        </div>
      )}
    </Card>
  );
};

export default VestingPool;
