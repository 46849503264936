import { AppChain, playnityConfig, terraExecuteContract, toWei } from 'helpers';
import { StakingPoolItemData } from 'models';

import { LCDClient, MsgExecuteContract, TxInfo } from '@terra-money/terra.js';
import { ConnectedWallet } from '@terra-money/wallet-provider';

export const postUnstake = async (
  stakingPoolItemData: StakingPoolItemData,
  unstakingValue: string,
  decimals: number,
  connectedAddress: string,
  terraConnectedWallet: ConnectedWallet,
  terra: LCDClient
): Promise<any> => {
  const unstakingValueWei = toWei(unstakingValue, decimals);

  switch (process.env.REACT_APP_CHAIN) {
    case AppChain.Bsc:
    case AppChain.Ethereum:
      return await postEvmUnstake(
        stakingPoolItemData,
        unstakingValueWei,
        connectedAddress
      );

    case AppChain.Terra:
      return await postTerraUnstake(
        stakingPoolItemData,
        unstakingValueWei,
        connectedAddress,
        terraConnectedWallet,
        terra
      );

    default:
      throw new Error('Unsupported Chain');
  }
};

const postEvmUnstake = async (
  stakingPoolItemData: StakingPoolItemData,
  unstakingValueWei: string,
  connectedAddress: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  return await stakingPoolItemData.stakingPoolItemContract.methods
    .unstake(unstakingValueWei)
    .send({
      from: connectedAddress,
    });
};
const postTerraUnstake = async (
  stakingPoolItemData: StakingPoolItemData,
  unstakingValueWei: string,
  connectedAddress: string,
  terraConnectedWallet: ConnectedWallet,
  terra: LCDClient
): Promise<TxInfo> => {
  const unstakeMsg: MsgExecuteContract = new MsgExecuteContract(
    connectedAddress,
    stakingPoolItemData.contractAddress,
    {
      submit_to_unbond: {
        amount: unstakingValueWei,
      },
    },
    {
      uusd: playnityConfig.playnityFee,
    }
  );
  const tx: TxInfo = await terraExecuteContract(
    terraConnectedWallet,
    terra,
    unstakeMsg,
    connectedAddress
  );
  return tx;
};
