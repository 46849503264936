import { PairData } from 'models';
import { State } from 'store';
import { LpPairState } from 'store/reducers';

import { createSelector } from '@reduxjs/toolkit';

const selectLpPair = (state: State): LpPairState => state.lpPair;

export const selectLpPairData = createSelector(
  [selectLpPair],
  (lpPairState: LpPairState): PairData => lpPairState?.lpPairData
);

export const selectTokensInLpPair = createSelector(
  [selectLpPair],
  (lpPairState: LpPairState): string => lpPairState?.tokensInLpPair
);

export const selectTotalLp = createSelector(
  [selectLpPair],
  (lpPairState: LpPairState): string => lpPairState?.totalLP
);
