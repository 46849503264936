import { AllocationPoolData } from 'models';

import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';

export interface AllocationState {
  pools: AllocationPoolData[];
}

export const initialAllocationState: AllocationState = {
  pools: null,
};

export const allocationSlice = createSlice<
  AllocationState,
  SliceCaseReducers<AllocationState>,
  string
>({
  name: 'allocation',
  initialState: initialAllocationState,
  reducers: {
    setAllocationPoolsData: (
      state,
      action: PayloadAction<AllocationPoolData[]>
    ) => {
      state.pools = action.payload;
    },
    resetAllocationState: (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = initialAllocationState;
    },
  },
});
