import BigNumber from 'bignumber.js';
import { AppChain, fromWei } from 'helpers';
import { TokenData, VestingPoolData, VestingPoolVestingData } from 'models';
import { Contract } from 'web3-eth-contract';

import { LCDClient } from '@terra-money/terra.js';

export enum VestingPoolType {
  Team = 'Team',
  Seed = 'Seed',
  Private = 'Private',
  Public = 'Public',
}

export const isInVestingPool = async (
  connectedAddress: string,
  contract?: Contract,
  contractAddress?: string,
  terra?: LCDClient
): Promise<boolean> => {
  switch (process.env.REACT_APP_CHAIN) {
    case AppChain.Bsc:
    case AppChain.Ethereum:
      return await isInVestingPoolEvm(contract, connectedAddress);
    case AppChain.Terra:
      return await isInVestingPoolTerra(
        terra,
        contractAddress,
        connectedAddress
      );
  }
};

export const isInVestingPoolEvm = async (
  contract: Contract,
  connectedAddress: string
): Promise<boolean> => {
  try {
    const amountAllocated = await contract.methods
      .amountAllocated(connectedAddress)
      .call();
    return amountAllocated != new BigNumber(0);
  } catch (e) {
    console.log(e);

    return false;
  }
};

export const isInVestingPoolTerra = async (
  terra: LCDClient,
  contractAddress: string,
  connectedAddress: string
): Promise<boolean> => {
  try {
    const result: { is_in_vesting: boolean } = await terra.wasm.contractQuery(
      contractAddress,
      {
        user_vesting: {
          address: connectedAddress,
        },
      }
    );

    return result.is_in_vesting;
  } catch (e) {
    console.log(e);

    return false;
  }
};

export const getVestingPoolData = async (
  connectedAddress: string,
  tokenData: TokenData,
  vestingPoolData: VestingPoolData,
  terra: LCDClient
): Promise<VestingPoolVestingData> => {
  switch (process.env.REACT_APP_CHAIN) {
    case AppChain.Bsc:
    case AppChain.Ethereum:
      return await getEvmVestingPoolData(
        vestingPoolData.vestingPoolContract,
        connectedAddress,
        tokenData
      );
    case AppChain.Terra:
      return await getTerraVestingPoolData(
        vestingPoolData,
        connectedAddress,
        tokenData,
        terra
      );
    default:
      throw new Error('Unsupported Chain');
  }
};

export const getEvmVestingPoolData = async (
  vestingContract: Contract,
  connectedAddress: string,
  tokenData: TokenData
): Promise<VestingPoolVestingData> => {
  // Allocated
  const amountAllocatedWei = await vestingContract.methods
    .amountAllocated(connectedAddress)
    .call();
  const amountAllocated = fromWei(amountAllocatedWei, tokenData.decimals);
  // Claimable
  const amountClaimableWei = await vestingContract.methods
    .amountClaimable(connectedAddress)
    .call();
  const amountClaimable = fromWei(amountClaimableWei, tokenData.decimals);
  // Left
  const amountLeftWei = await vestingContract.methods
    .amountLeft(connectedAddress)
    .call();
  const amountLeft = fromWei(amountLeftWei, tokenData.decimals);

  return {
    amountAllocated,
    amountClaimable,
    amountLeft,
  };
};

export const getTerraVestingPoolData = async (
  vestingPoolData: VestingPoolData,
  connectedAddress: string,
  tokenData: TokenData,
  terra: LCDClient
): Promise<VestingPoolVestingData> => {
  interface VestingAccountQueryResult {
    address: string;
    info: {
      amount: string;
      already_claimed: string;
    };
    possible_claim: string;
  }
  const result: VestingAccountQueryResult = await terra.wasm.contractQuery(
    vestingPoolData.contractAddress,
    {
      vesting_account: {
        address: connectedAddress,
      },
    }
  );

  const amountAllocated: BigNumber = new BigNumber(result.info.amount);
  const amountClaimed: BigNumber = new BigNumber(result.info.already_claimed);
  const amountLeft: BigNumber = amountAllocated.minus(amountClaimed);
  const amountClaimable: string = fromWei(
    result.possible_claim,
    tokenData.decimals
  );

  return {
    amountAllocated: fromWei(amountAllocated, tokenData.decimals),
    amountLeft: fromWei(amountLeft, tokenData.decimals),
    amountClaimable,
  };
};
