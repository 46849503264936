import classNames from 'classnames';
import { Message } from 'components';
import { MessageText, MessageType, registerVote } from 'helpers';
import { GovernancePollItem, GovernancePollStatus } from 'models';
import React, { useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getGovernancePolls } from 'store';
import Web3 from 'web3';

import GovernancePollBody from './GovernancePollBody';
import GovernancePollHeader from './GovernancePollHeader';

export interface GovernancePollProps {
  index: number;
  connectedAddress: string;
  governancePollItem: GovernancePollItem;
  web3: Web3;
}

const GovernancePoll: React.FC<GovernancePollProps> = ({
  index,
  connectedAddress,
  governancePollItem,
  web3,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isExpanded, setIsExpanded] = useState(index === 0);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (loading === false) {
      dispatch(getGovernancePolls(connectedAddress));
    }
  }, [loading]);

  useEffect(() => {
    const active =
      governancePollItem?.status === GovernancePollStatus.Active ||
      governancePollItem?.status === GovernancePollStatus.Sealed;

    setIsActive(active);
  }, [governancePollItem]);

  const onVote = async (answerId: number) => {
    setLoading(true);
    setErrorMessage('');

    try {
      await registerVote(
        connectedAddress,
        governancePollItem.id,
        answerId,
        web3
      );
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setErrorMessage((error as any).message);
    }

    setLoading(false);
  };

  const classes = classNames('governance-poll', { inactive: !isActive });

  return (
    <div className={classes}>
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey={index.toString()}
          onClick={() => {
            isActive && setIsExpanded(!isExpanded);
          }}
        >
          <GovernancePollHeader
            governancePollItem={governancePollItem}
            isActive={isActive}
            isExpanded={isExpanded}
            index={index}
          />
        </Accordion.Toggle>

        <Accordion.Collapse
          eventKey={index.toString()}
          unmountOnExit={true}
          mountOnEnter={true}
        >
          <Card.Body>
            <GovernancePollBody
              governancePollItem={governancePollItem}
              loading={loading}
              isActive={isActive}
              onVote={(answerId) => onVote(answerId)}
            />

            {errorMessage && (
              <Message
                descriptionText={
                  errorMessage.includes('404')
                    ? `${errorMessage} ${MessageText.Fees}`
                    : errorMessage
                }
                messageType={MessageType.Error}
              />
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </div>
  );
};

export default GovernancePoll;
