import { HallOfFameRecord } from 'models';
import { hallOfFameActions } from 'store';

import { Action, Dispatch } from '@reduxjs/toolkit';

export const getHallOfFameItems = (): ((
  dispatch: Dispatch<Action>
) => void) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      const response: Response = await fetch(
        `${process.env.REACT_APP_API_URL}hof`
      );
      const hallOfFame: HallOfFameRecord[] = await response.json();

      dispatch(hallOfFameActions.setHallOfFameItems(hallOfFame));
    } catch (e) {
      console.log(e);
    }
  };
};
