import { AppChain, terraExecuteContract } from 'helpers';
import { StakingPoolItemData } from 'models';

import { LCDClient, MsgExecuteContract, TxInfo } from '@terra-money/terra.js';
import { ConnectedWallet } from '@terra-money/wallet-provider';

export const postGetReward = async (
  stakingPoolItemData: StakingPoolItemData,
  connectedAddress: string,
  terraConnectedWallet: ConnectedWallet,
  terra: LCDClient
) => {
  switch (process.env.REACT_APP_CHAIN) {
    case AppChain.Bsc:
    case AppChain.Ethereum:
      return await postEvmGetReward(stakingPoolItemData, connectedAddress);

    case AppChain.Terra:
      return await postTerraGetReward(
        stakingPoolItemData,
        connectedAddress,
        terraConnectedWallet,
        terra
      );

    default:
      console.error('Unsupported chain');
  }
};

const postEvmGetReward = async (
  stakingPoolItemData: StakingPoolItemData,
  connectedAddress: string
): Promise<void> => {
  return await stakingPoolItemData.stakingPoolItemContract.methods
    .getReward()
    .send({
      from: connectedAddress,
    });
};

const postTerraGetReward = async (
  stakingPoolItemData: StakingPoolItemData,
  connectedAddress: string,
  terraConnectedWallet: ConnectedWallet,
  terra: LCDClient
): Promise<TxInfo> => {
  const claimMsg: MsgExecuteContract = new MsgExecuteContract(
    connectedAddress,
    stakingPoolItemData.contractAddress,
    {
      withdraw: {},
    }
  );
  const tx: TxInfo = await terraExecuteContract(
    terraConnectedWallet,
    terra,
    claimMsg,
    connectedAddress
  );

  return tx;
};
