import { TokenData } from 'models';

import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';

export interface TokenState {
  tokensData: TokenData[];
}

export const initialTokenState: TokenState = {
  tokensData: null,
};

export const tokenSlice = createSlice<
  TokenState,
  SliceCaseReducers<TokenState>,
  string
>({
  name: 'token',
  initialState: initialTokenState,
  reducers: {
    setTokensData: (state, action: PayloadAction<TokenData[]>) => {
      state.tokensData = action.payload;
    },
    resetTokensState: (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = initialTokenState;
    },
  },
});
