/* eslint-disable @typescript-eslint/no-unused-vars */
import { isProduction } from 'helpers/environment.helper';
import {
  AppConfig,
  PlaynityTokenName,
  StakingPoolConfig,
  StakingPoolItemName,
  StakingPoolItemType,
  StakingPoolName,
  StakingPoolType,
  TokenSymbol,
} from 'models';

import LPPairContractJSON from './contracts/LPPair.json';
import tokenLPContractJSON from './contracts/LPToken.json';
import SCTokenJSON from './contracts/SCToken.json';
// import stakingLPTwelveMonthsContractJSON from './contracts/StakingLP12Months.json';
import stakingLPThreeMonthsContractJSON from './contracts/StakingLP3Months.json';
// import stakingLPSixMonthsContractJSON from './contracts/StakingLP6Months.json';
import stakingLPLiquidContractJSON from './contracts/StakingLPLiquid.json';
// import stakingSATwelveMonthsContractJSON from './contracts/StakingSA12Months.json';
// import stakingSAThreeMonthsContractJSON from './contracts/StakingSA3Months.json';
// import stakingSASixMonthsContractJSON from './contracts/StakingSA6Months.json';
import stakingSALiquidContractJSON from './contracts/StakingSALiquid.json';
import tokenContractJSON from './contracts/Token.json';

// TODO: Move to config.model / helper ( avoid circular dependency - separate MR)
export enum EthNetworkType {
  Mainnet = '1',
  // ? Same networkId as BSC
  Testnet = '97',
}

export const availableEthNetworks = {
  0: {
    name: 'mainnet',
    chainID: EthNetworkType.Mainnet,
  },
  ...(!isProduction() && {
    1: {
      name: 'testnet',
      chainID: EthNetworkType.Testnet,
    },
  }),
};

const LPStakingPool: StakingPoolConfig = {
  poolType: StakingPoolType.LP,
  poolDescription: 'PLY-USDT',
  poolName: StakingPoolName.LP,
  poolItems: [
    {
      poolItemName: StakingPoolItemName.Liquid,
      poolItemtype: StakingPoolItemType.Bonded,
      stakingContractJSON: stakingLPLiquidContractJSON,
    },
    {
      poolItemName: StakingPoolItemName.ThreeMonths,
      poolItemtype: StakingPoolItemType.Locked,
      stakingContractJSON: stakingLPThreeMonthsContractJSON,
      lockPeriod: 3,
    },
  ],
};

const SAStakingPool: StakingPoolConfig = {
  poolType: StakingPoolType.SA,
  poolDescription: 'PLY',
  poolName: StakingPoolName.SA,
  poolItems: [
    {
      poolItemName: StakingPoolItemName.Liquid,
      poolItemtype: StakingPoolItemType.Bonded,
      stakingContractJSON: stakingSALiquidContractJSON,
    },
  ],
};

const activeStakingPools: StakingPoolConfig[] = [LPStakingPool, SAStakingPool];

export const ethConfig: AppConfig = {
  playnityFee: '0.3%',
  maxUnbondingsPerAccount: 3,
  maxLockedStakesPerAccount: 10,
  permittedNetworks: Object.values(availableEthNetworks).map(
    (ethNetwork) => ethNetwork.chainID
  ),
  tokens: [
    {
      name: PlaynityTokenName.Playnity,
      symbol: TokenSymbol.PLY,
      decimals: 6,
      tokenContractJSON: tokenContractJSON,
    },
    {
      name: PlaynityTokenName.LPPlaynity,
      symbol: TokenSymbol.LPPLY,
      decimals: 18,
      tokenContractJSON: tokenLPContractJSON,
    },
    {
      name: PlaynityTokenName.Stablecoin,
      symbol: TokenSymbol.USDT,
      decimals: 6,
      tokenContractJSON: SCTokenJSON,
    },
  ],
  vesting: {
    pools: [],
  },
  staking: {
    pools: activeStakingPools,
  },
  LPPair: {
    contractJSON: LPPairContractJSON,
  },
  additionalData: {
    tokenAddress: '0x20d60c6eb195868d4643f2c9b0809e4de6cc003d',
    tokenChartLink:
      'https://www.dextools.io/app/ether/pair-explorer/0xe62f9cc7241d25030725183fb687547959580f40',
    tokenLink:
      'https://app.sushi.com/swap?inputCurrency=0x20d60c6eb195868d4643f2c9b0809e4de6cc003d&outputCurrency=0xdac17f958d2ee523a2206206994597c13d831ec7',
    lpPairLink:
      'https://app.sushi.com/add/0x20d60c6eb195868d4643f2c9B0809e4De6Cc003d/0xdAC17F958D2ee523a2206206994597C13D831ec7',
  },
};
