export enum MessageType {
  Error = 'error',
  Primary = 'primary',
  Secondary = 'secondary',
}

export enum MessageText {
  ValueGreaterThanApproved = 'Entered value is greated than approved value. Please approve first',
  UnstakingValueNotGreaterThanStaked = 'Value should not be greater than staked tokens',
  NoTokens = "You don't have any tokens.",
  NothingToClaim = "You don't have anything to claim yet. Please come back later",
  EverythingClaimed = 'You have claimed everything from this pool',
  Approve = 'To stake tokens you need to approve them first',
  Deposit = 'To deposit tokens you need to approve them first',
  Fees = 'Make sure to have enough UST available in your wallet for fees (1 UST + network fees)',
}
