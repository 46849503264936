import React from 'react';

export interface LeaderboardTableRankProps {
  rankImage: string;
  rank?: number;
  changeIcon?: string;
  name?: string;
  rankClass: string;
}

export const LeaderboardTableRank: React.FC<LeaderboardTableRankProps> = ({
  rankImage,
  rank,
  changeIcon,
  name,
  rankClass,
}) => {
  return (
    <div
      className={`leaderboard-table-rank ${rankClass ?? ''} ${
        name ? 'flex-container align-center' : ''
      }`}
    >
      <div className="rank-image-wrapper">
        <img className="rank-image" src={rankImage} />
        {rank ? <span className="rank-number">{rank}</span> : ''}
      </div>
      {name ? <div className="rank-name">{name}</div> : ''}

      {changeIcon ? (
        <span className="rank-change">
          {changeIcon !== '-' ? (
            <i className={`fas ${changeIcon}`}></i>
          ) : (
            <span className="font-bold font-large">-</span>
          )}
        </span>
      ) : (
        ''
      )}
    </div>
  );
};

export default LeaderboardTableRank;
