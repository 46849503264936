import bondedImage from 'assets/images/bonded.png';
import BigNumber from 'bignumber.js';
import { ButtonWithSpinner, DataItem, Message } from 'components';
import dayjs from 'dayjs';
import { DateFormat, roundNumber } from 'helpers';
import {
  StakingPoolItemBondedTokensData,
  StakingPoolItemStakingData,
  TokenSymbol,
} from 'models';
import React from 'react';
import { Card } from 'react-bootstrap';

interface BondedTokensProps {
  loading: boolean;
  staking: StakingPoolItemStakingData;
  bondedTokensData: StakingPoolItemBondedTokensData[];
  tokenSymbol: TokenSymbol;
  onWithdraw: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    instant?: boolean
  ) => void;
}

// TODO: Unify with locked (interfaces, classes)
export const BondedTokens: React.FC<BondedTokensProps> = ({
  loading,
  staking,
  bondedTokensData,
  tokenSymbol,
  onWithdraw,
}) => {
  const unbondingTableItems = bondedTokensData.map(
    (unbondingTableItem: StakingPoolItemBondedTokensData, index: number) => {
      // TODO
      // EVM
      // const timestamp = await web3.eth.getBlock(await web3.eth.getBlockNumber()))
      //               .timestamp
      // TERRA
      // https://learn.figment.io/tutorials/setting-up-your-first-wallet
      const isAvailableForWithdrawal =
        +unbondingTableItem.timestamp <= dayjs().unix();

      return (
        <div
          className={`bonded-tokens-table-item ${
            isAvailableForWithdrawal ? 'ready' : ''
          }`}
          key={`bonded-tokens-table-item-${index}`}
        >
          <div className="time">
            {isAvailableForWithdrawal
              ? 'Available for withdrawal'
              : dayjs(+unbondingTableItem.timestamp * 1000).format(
                  DateFormat.DateWithTime
                )}
          </div>
          <div className="amount">
            {roundNumber(unbondingTableItem.amount)} {tokenSymbol}
          </div>
        </div>
      );
    }
  );

  const availableForWithdrawalBN: BigNumber = new BigNumber(
    staking.availableForWithdrawal
  );
  const totalInUnbondingBN: BigNumber = new BigNumber(staking.totalInUnbonding);
  const bondedBN: BigNumber = totalInUnbondingBN.minus(
    availableForWithdrawalBN
  );

  return (
    <Card body className="bonded-tokens">
      <div className="bonded-tokens-header">
        <img className="bonded-tokens-image" src={bondedImage} />
        <div className="bonded-tokens-message">
          <Message headerText="Bonding details">
            <DataItem
              label="Bonded tokens"
              data={staking.totalInUnbonding}
              symbol={tokenSymbol}
              classes="horizontal justify-between"
            />
            <DataItem
              label="Available for withdrawal"
              data={staking.availableForWithdrawal}
              symbol={tokenSymbol}
              classes="horizontal justify-between mt-8"
            />
          </Message>
        </div>
      </div>
      <div className="bonded-tokens-table">
        <div className="bonded-tokens-table-header">
          <div className="time">
            Bonded {+staking.unbondingPeriod / 86400} days till
            <span className="time-amount-separator"> / </span>
          </div>
          <div className="amount">Bonded amount</div>
        </div>
        {unbondingTableItems}
        <div className="bonded-tokens-buttons">
          <div>
            <div
              className={`playnity-btn-wrapper aquamarine ${
                availableForWithdrawalBN.isEqualTo(0) ? 'disabled' : ''
              }`}
            >
              <ButtonWithSpinner
                classes="playnity-btn-outline btn-no-fee"
                onClick={onWithdraw}
                disabled={availableForWithdrawalBN.isEqualTo(0)}
                loading={loading}
              >
                Withdraw
                <div>
                  {roundNumber(staking.availableForWithdrawal)} {tokenSymbol}
                </div>
              </ButtonWithSpinner>
            </div>
            <div className="fee-text">no fee</div>
          </div>
          <div>
            <ButtonWithSpinner
              classes="btn-style-three btn-fee"
              onClick={(event) => onWithdraw(event, true)}
              disabled={bondedBN.isEqualTo(0)}
              loading={loading}
            >
              Instant Withdraw
              <div>
                {roundNumber(staking.totalInUnbonding)} {tokenSymbol}
              </div>
            </ButtonWithSpinner>
            <div className="fee-text">
              with fee {staking.instantWithdrawalPenalty}% of{' '}
              {roundNumber(bondedBN.toString(10))}
              {/* {bondedBN.isGreaterThan(0) &&
                bondedBN
                  .times(parseFloat(staking.instantWithdrawalPenalty) / 100.0)
                  .toString()} */}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
