import { experienceLevels } from 'helpers';
import { UserExperienceData } from 'models';
import React from 'react';
import {
  buildStyles,
  CircularProgressbar,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';

export interface ExperienceCircularProgressBarProps {
  percentage: number;
  userExperienceData: UserExperienceData;
}

export const ExperienceCircularProgressBar: React.FC<
  ExperienceCircularProgressBarProps
> = ({ percentage, userExperienceData }) => {
  return (
    //   <ExperienceProgressProvider valueStart={0} valueEnd={percentage}>
    // {(value: number) => ( */}
    // ADD GRADIENT
    // https://github.com/kevinsqi/react-circular-progressbar/issues/31#issuecomment-338216925 */}
    <CircularProgressbarWithChildren
      value={percentage}
      text={userExperienceData.curLevel.toString()}
      strokeWidth={3}
      styles={buildStyles({
        // Rotation of path and trail, in number of turns (0-1)
        // rotation: 0.25,

        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
        strokeLinecap: 'round',

        // Text size
        // textSize: '28px',

        // How long animation takes to go from one percentage to another, in seconds
        pathTransitionDuration: 1,
        // Rotate the path

        // Can specify path transition in more detail, or remove it entirely
        // pathTransition: 'none',

        // Colors
        // pathColor: `rgba(67, 228, 255, ${percentage / 100})`,
        pathColor: '#43E4FF',
        textColor: '#209ed9',
        trailColor: '#446581',
        // backgroundColor: '#446581',
      })}
    >
      <div style={{ width: '85%' }}>
        <CircularProgressbar
          value={(userExperienceData.curLevel / experienceLevels.length) * 100}
          styles={buildStyles({
            trailColor: 'transparent',
            pathColor: '#209ed9',
            strokeLinecap: 'round',
          })}
        />
      </div>
    </CircularProgressbarWithChildren>
    //   </ExperienceProgressProvider>
  );
};
