import noRankImage from 'assets/images/leaderboard/no-rank-big.svg';
import { SectionDetails, SectionLoader } from 'components';
import { leaderboardTableRowRankData } from 'helpers';
import { LeaderboardAddress, LeaderboardTableRowRankData } from 'models';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLeaderboardData,
  selectConnectedAddress,
  selectLeaderboardAddresses,
  selectLeaderboardCurrentAddressData,
} from 'store';

import { LeaderboardTable } from './';
import LeaderboardTableRank from './LeaderboardTableRank';

export const Leaderboard: React.FC = () => {
  const dispatch = useDispatch();
  const leaderboardAddresses = useSelector(selectLeaderboardAddresses);
  const leaderboardCurrentAddressData = useSelector(
    selectLeaderboardCurrentAddressData
  );
  const connectedAddress = useSelector(selectConnectedAddress);
  const connectedAddressRank: number | undefined = leaderboardAddresses?.find(
    (leaderboardAddress: LeaderboardAddress) =>
      leaderboardAddress.address === connectedAddress
  )?.rank;
  const connectedAddressRankData = leaderboardTableRowRankData.find(
    (rankData: LeaderboardTableRowRankData) =>
      connectedAddressRank <= rankData.maxLevel
  );

  useEffect(() => {
    dispatch(getLeaderboardData(connectedAddress));
  }, []);

  return (
    <div className="leaderboard">
      <SectionDetails title="Top 200 Leaderboard">
        <div>
          This is the current staking leaderboard regarding the USD values of
          all staked PLY/LP tokens by the wallet addresses on all supported
          chains.
        </div>
        <div>Check your position daily, it is refreshed every single day!</div>
      </SectionDetails>

      <div className="leaderboard-header">
        <div className="leaderboard-legend">
          {leaderboardTableRowRankData.map((rankData, index) => (
            <div
              className="leaderboard-legend-item"
              key={`legend-rank-${index}`}
            >
              <LeaderboardTableRank
                rankImage={rankData.rankImage}
                rankClass={`${rankData.rankClass} small`}
                name={rankData.name}
              />
              <div className="description">{rankData.description}</div>
            </div>
          ))}
        </div>
        <div className="leaderboard-info">
          <>
            <div
              className={`leaderboard-info-rank ${
                connectedAddressRank
                  ? connectedAddressRankData.rankClass
                  : 'no-rank'
              }`}
            >
              <div className="leaderboard-info-rank-image-wrapper">
                <img
                  className="leaderboard-info-rank-image"
                  src={
                    connectedAddressRank
                      ? connectedAddressRankData.rankImageBig
                      : noRankImage
                  }
                />
                {connectedAddressRank ? (
                  <span className="rank-number">{connectedAddressRank}</span>
                ) : (
                  ''
                )}
              </div>
              <div className="leaderboard-info-rank-text">
                {connectedAddressRank ? 'Congrats!' : 'Try harder!'}
              </div>
            </div>
            {connectedAddressRank ? (
              <>
                <div>
                  Your address is {connectedAddressRank} on the leaderboard.
                </div>
                <a className="link" href={`#connected-address-rank`}>
                  Click here to scroll to your position
                </a>{' '}
              </>
            ) : (
              <div>
                {leaderboardCurrentAddressData ? (
                  <>
                    <div className="mb-8">
                      You need to stake more to get to the top 200 addresses
                    </div>
                    <a className="link" href={`#connected-address-rank`}>
                      Click here to scroll to your position
                    </a>
                  </>
                ) : (
                  <>
                    Unfortunately, your address is currently out of the
                    leaderboard
                  </>
                )}
              </div>
            )}
          </>
        </div>
      </div>

      {leaderboardAddresses ? (
        <LeaderboardTable
          leaderboardAddresses={leaderboardAddresses}
          leaderboardCurrentAddressData={leaderboardCurrentAddressData}
          connectedAddress={connectedAddress}
        />
      ) : (
        <SectionLoader />
      )}
    </div>
  );
};
