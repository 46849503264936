import { Contract } from 'web3-eth-contract';

import { ContractJSON } from './contract.model';

export enum AllocationPoolType {
  SA = 'Single Asset Staking',
  SC = 'Stablecoin Staking',
}

export enum AllocationPoolName {
  SCAllocation = 'USD-based Allocation',
  PLYAllocation = 'PLY-based Allocation',
}

export enum AllocationPoolItemName {
  BUSDSkynityAllocation2 = 'SkyNity SDT pre-game sale for PEX collectors - BUSD allocation',
  BUSDSkynityAllocation = 'SkyNity SDT presale for PEX collectors - BUSD allocation',
  PLYSkynityAllocation = 'SkyNity SDT presale for PEX collectors - PLY allocation',
  PLYSkynityAllocation2 = 'SkyNity SDT presale - PLY allocation (1 $SDT = 8 $PLY)',
}

export interface AllocationPoolItemAllocationData {
  allocationAllowance: string;
  allocatedTokens: string;
  allocationLimit: string;
  allocationLeft: string;
  totalSupply: string;
  // isAllocated: boolean;
  // blockTimestamp: string;
}

export interface AllocationConfig {
  pools: AllocationPoolConfig[];
  allocationLimit: number;
}

export interface AllocationPoolItemConfig {
  poolItemName: AllocationPoolItemName;
  poolItemContractJSON: ContractJSON;
  poolItemDescription?: string;
  hasWhitelist: boolean;
  isActive: boolean;
}

export interface AllocationPoolItemData {
  poolItemName: AllocationPoolItemName;
  poolItemContract: Contract;
  addressCanAllocate?: boolean;
  isActive?: boolean;
}

export interface AllocationPoolConfig {
  poolType: AllocationPoolType;
  poolName: AllocationPoolName;
  poolDescription: string;
  poolItems: AllocationPoolItemConfig[];
}

export interface AllocationPoolData {
  poolType: AllocationPoolType;
  poolName: AllocationPoolName;
  poolDescription: string;
  poolItems: AllocationPoolItemData[];
}
