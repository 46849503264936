import { SectionLoader } from 'components';
import {
  checkEvmAddressLinkStatus,
  isValidEvmAddress,
  linkEvmAddress,
  MessageText,
  MessageType,
} from 'helpers';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectConnectedAddress, selectTerra } from 'store';

import { ButtonWithSpinner } from './ButtonWithSpinner';
import { Message } from './Message';
import { SectionDetails } from './SectionDetails';

import { useConnectedWallet } from '@starterra/starterra-tool-dapp';

export const LinkEvmAddress: React.FC = () => {
  const [evmAddress, setEvmAddress] = useState('');
  const [hasLinkedEvmAddress, setHasLinkedEvmAddress] = useState(null);
  const [linkedEvmAddress, setLinkedEvmAddress] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const connectedAddress = useSelector(selectConnectedAddress);

  const terra = useSelector(selectTerra);
  const terraConnectedWallet = useConnectedWallet();

  useEffect(() => {
    if (connectedAddress) {
      getData();
    }
  }, [connectedAddress]);

  const onLinkAddress = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage('');

    try {
      await linkEvmAddress(
        connectedAddress,
        evmAddress,
        terraConnectedWallet,
        terra
      );
      setHasLinkedEvmAddress(true);
      setLinkedEvmAddress(evmAddress);
      setEvmAddress('');
    } catch (e) {
      setErrorMessage((e as any).message);
    }

    setLoading(false);
  };

  const getData = async () => {
    try {
      const status = await checkEvmAddressLinkStatus(connectedAddress, terra);
      setHasLinkedEvmAddress(status.linked);
      setLinkedEvmAddress(status.address);
    } catch (e: any) {
      console.log(e);
    }
  };

  return (
    <div className="nft-address-link" style={{ fontFamily: 'Roboto' }}>
      <SectionDetails
        title="EVM Address Link"
        description="On this page you can link your EVM-compatible (BSC/Ethereum/Polygon etc.) wallet adddress to your current Terra Classic address."
      ></SectionDetails>

      {hasLinkedEvmAddress !== null ? (
        <>
          <div className="mb-16">
            This will allow us to properly identify you on other
            Playnity-supported networks, so that your previous activity on the
            Terra Classic network (such as PEX history) can be accounted for on
            other networks.
          </div>

          <div className="mb-16">
            Your current Terra Classic wallet address is currently
            {hasLinkedEvmAddress ? (
              <>
                <span className="green"> linked </span> to an EVM-compatible
                wallet address <strong>{linkedEvmAddress}</strong>.
              </>
            ) : (
              <>
                <span className="orange"> not linked </span> to any
                EVM-compatible wallet address.
              </>
            )}
          </div>
          {hasLinkedEvmAddress ? (
            ''
          ) : (
            <>
              <div>
                Enter your EVM-compatible (BSC/Ethereum/Polygon etc.) address to
                link it to your current Terra Classic wallet address:
              </div>
              <div className="mt-16 flex-container align-center justify-between wrap">
                <div className="playnity-input-wrapper grow-1 nft-address-link-input">
                  <input
                    className="playnity-input"
                    placeholder="Enter an EVM-compatible address"
                    onChange={(e) => setEvmAddress(e.target.value)}
                    value={evmAddress}
                  />
                </div>
                <ButtonWithSpinner
                  text="Sign &amp; Send"
                  classes="btn-style-two nft-address-link-button"
                  onClick={onLinkAddress}
                  loading={loading}
                  disabled={!evmAddress || !isValidEvmAddress(evmAddress)}
                />
              </div>
            </>
          )}
        </>
      ) : (
        <SectionLoader />
      )}
      {evmAddress && !isValidEvmAddress(evmAddress) && (
        <Message
          descriptionText={'Invalid EVM address'}
          messageType={MessageType.Error}
        />
      )}
      {errorMessage && (
        <Message
          descriptionText={
            errorMessage.includes('404')
              ? `${errorMessage} ${MessageText.Fees}`
              : errorMessage
          }
          messageType={MessageType.Error}
        />
      )}
    </div>
  );
};
