import { UserExperienceData } from 'models';

import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';

export interface ExperienceState {
  userExperienceData: UserExperienceData;
}

export const initialExperienceState: ExperienceState = {
  userExperienceData: null,
};

export const experienceSlice = createSlice<
  ExperienceState,
  SliceCaseReducers<ExperienceState>,
  string
>({
  name: 'experience',
  initialState: initialExperienceState,
  reducers: {
    setUserExperienceData: (
      state,
      action: PayloadAction<UserExperienceData>
    ) => {
      state.userExperienceData = action.payload;
    },
  },
});
