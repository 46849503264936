import { ButtonWithSpinner } from 'components';
import React from 'react';
import { Tab } from 'react-bootstrap';

interface StakingPoolItemClaimTabContentProps {
  loading: boolean;
  staking: {
    rewardTokens: string;
  };
  onClaim: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const StakingPoolItemClaimTabContent: React.FC<
  StakingPoolItemClaimTabContentProps
> = ({ loading, staking, onClaim }) => {
  const canClaim = !!+staking.rewardTokens;
  const claimSectionClasses = `claim-section ${
    canClaim ? 'claimable' : 'not-claimable'
  }`;
  const claimSectionHeader = canClaim
    ? 'Well Done'
    : 'You do not have any rewards.';
  const claimSectionDetails = (
    <div className="text">
      {canClaim ? 'You can claim your reward' : ' Please come back later'}
    </div>
  );
  // TODO: Get token symbol from reward token data
  {
    canClaim && <span className="amount">{staking.rewardTokens} PLY</span>;
  }

  return (
    <Tab.Pane eventKey="claim" unmountOnExit={true} mountOnEnter={true}>
      <div className="tab-content">
        <div className={claimSectionClasses}>
          <div>
            <div className="claim-section-info">
              <div className="claim-section-info-header">
                {claimSectionHeader}
              </div>
              <div className="claim-section-info-details">
                {claimSectionDetails}
              </div>
            </div>

            <ButtonWithSpinner
              text="Claim"
              classes="btn-style-three claim-section-button"
              onClick={onClaim}
              disabled={!canClaim}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </Tab.Pane>
  );
};
