import BigNumber from 'bignumber.js';
import {
  applyMask,
  fromWei,
  getLeaderboardRankClass,
  isProduction,
  leaderboardTableRowRankData,
} from 'helpers';
import { LeaderboardAddress } from 'models';
import React from 'react';

import { LeaderboardTableRank } from './';

export interface LeaderboardTableRowProps {
  connectedAddress: string;
  leaderboardAddress: LeaderboardAddress;
}

// TODO: Add chain to leaderboard
export const LeaderboardTableRow: React.FC<LeaderboardTableRowProps> = ({
  connectedAddress,
  leaderboardAddress,
}) => {
  const { rank, address, contributedUsd, previousRank } = leaderboardAddress;
  const isConnectedAddress = address === connectedAddress;
  const renderRank = (rank: number): JSX.Element => {
    let changeIcon = '-';

    if (rank > previousRank) {
      changeIcon = 'fa-caret-down';
    } else if (previousRank > rank) {
      changeIcon = 'fa-caret-up';
    }

    const applicableRankData = leaderboardTableRowRankData.find(
      (rankData) => rank <= rankData.maxLevel
    );

    return (
      <LeaderboardTableRank
        rank={rank}
        rankImage={applicableRankData?.rankImage}
        changeIcon={changeIcon}
        rankClass={applicableRankData?.rankClass}
      />
    );
  };

  return (
    <>
      {isConnectedAddress ? (
        <div id="connected-address-rank" className="connected-address-anchor">
          &nbsp;
        </div>
      ) : (
        ''
      )}

      <div
        className={`leaderboard-table-row ${
          isConnectedAddress ? 'connected' : ''
        } ${getLeaderboardRankClass(rank)}`}
      >
        <div className="flex-container align-center justify-between">
          {renderRank(rank)}
          <div className="address">
            {/* {isConnectedAddress
              ? leaderboardTableRowRankData.find(
                  (rankData) => rank <= rankData.maxLevel
                )?.description
              : applyMask(address)} */}
            {applyMask(address)}
          </div>
          {contributedUsd ? (
            <div className="value">
              {contributedUsd.toLocaleString('en', {
                style: 'currency',
                currency: 'USD',
              })}
              {!isProduction() && (
                <>
                  <br />
                  <small>
                    [DEV_ONLY:{' '}
                    {fromWei(
                      new BigNumber(leaderboardAddress.contributedPly),
                      6
                    )}{' '}
                    PLY]
                  </small>
                </>
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};
