import { AppChain, isChain } from 'helpers';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export interface MainModalProps {
  showModal: boolean;
  hideModal: () => void;
}

export const MainModal: React.FC<MainModalProps> = ({
  showModal,
  hideModal,
}) => {
  if (isChain(AppChain.Terra) || location.pathname != '/sdt-sale')
    return (
      <Modal
        show={showModal}
        onHide={() => hideModal()}
        className="experience-info-modal"
        centered
      >
        <Modal.Header closeButton className="align-center">
          <Modal.Title>Terra ecosystem recovery information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            Please note that Playnity is a multichain project and is also
            present on other networks. The project remains fully functional on
            the{' '}
            <a
              href="https://bsc.playnity.io"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              BSC Network
            </a>{' '}
            as well as Ethereum Network{' '}
            <a
              href="https://eth.playnity.io"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              Ethereum Mainnet
            </a>{' '}
            (with future multichain expansion plans to other networks).{' '}
          </div>

          <div className="mt-16">
            Your Terra Classic $PLY tokens can be bridged to other supported
            networks via the{' '}
            <a
              href="https://wormholebridge.com/#/transfer"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              Wormhole Bridge
            </a>
            .
          </div>

          <div className="mt-16">
            To fully take advantage of the multichain functionalities please{' '}
            <span onClick={() => hideModal()}>
              <Link className="link" to="/link-evm-address">
                provide us with your EVM-compatible
              </Link>{' '}
            </span>
            (BSC/Ethereum/Polygon etc.) wallet address, so that we can properly
            identify you on other Playnity-supported networks and that your
            previous activity on the Terra Classic network (such as PEX history)
            can be accounted for on other networks.
          </div>
        </Modal.Body>
      </Modal>
    );
  else return <></>;
};
