import { StakingPoolData } from 'models';

import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';

export interface StakingState {
  pools: StakingPoolData[];
}

export const initialStakingState: StakingState = {
  pools: null,
};

export const stakingSlice = createSlice<
  StakingState,
  SliceCaseReducers<StakingState>,
  string
>({
  name: 'staking',
  initialState: initialStakingState,
  reducers: {
    setStakingPoolsData: (state, action: PayloadAction<StakingPoolData[]>) => {
      state.pools = action.payload;
    },
    resetStakingState: (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = initialStakingState;
    },
  },
});
