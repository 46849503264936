import classNames from 'classnames';
import { ButtonWithSpinner } from 'components/ButtonWithSpinner';
import dayjs from 'dayjs';
import { DateFormat } from 'helpers';
import { GovernancePollItem, GovernancePollStatus } from 'models';
import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';

export interface GovernancePollBodyProps {
  governancePollItem: GovernancePollItem;
  loading: boolean;
  isActive: boolean;
  onVote: (answerId: number) => void;
}

const GovernancePollBody: React.FC<GovernancePollBodyProps> = ({
  governancePollItem,
  loading,
  isActive,
  onVote,
}) => {
  const [checkedValue, setCheckedValue] = useState(null);
  const [voted, setVoted] = useState(null);

  useEffect(() => {
    if (governancePollItem.voted_value !== null) {
      setCheckedValue(governancePollItem.voted_value);
      setVoted(true);
    }
  }, [governancePollItem]);

  const getDescriptionHTML = (
    descriptionHtmlString: string
  ): { __html: string } => {
    return { __html: descriptionHtmlString };
  };

  const showResults = governancePollItem.status === GovernancePollStatus.Sealed;

  const formCheckDisabled = (index: number): boolean => {
    return (
      (!showResults && voted && checkedValue !== index) ||
      loading ||
      governancePollItem.voted_value === index
    );
  };
  const voteStart = dayjs
    .unix(governancePollItem.voting_start)
    .utc()
    .format(DateFormat.DateWithTime);
  const voteEnd = dayjs
    .unix(governancePollItem.voting_end)
    .utc()
    .format(DateFormat.DateWithTime);

  return (
    <div className="governance-poll-body">
      {!showResults ? (
        <div>
          Voting takes place from {voteStart} UTC till {voteEnd} UTC.
        </div>
      ) : (
        <>Voting finished on {voteEnd}</>
      )}
      {!showResults ? (
        <div
          className="mt-16"
          dangerouslySetInnerHTML={getDescriptionHTML(
            governancePollItem.description
          )}
        ></div>
      ) : (
        ''
      )}
      <div className="orange mt-16">{governancePollItem.question}</div>
      <div
        className={classNames('mt-16 answers-list', {
          voted: governancePollItem.voted,
          results: showResults,
        })}
      >
        {governancePollItem.options.map((option, index) => {
          return (
            <div key={`radio-button-${index}`}>
              <label
                className={classNames('radio-label', {
                  disabled: formCheckDisabled(index),
                  voted: governancePollItem.voted_value === index,
                  result: showResults && governancePollItem.result === index,
                })}
              >
                {option.text}
                {!showResults ? (
                  <>
                    <input
                      className="radio-input"
                      type="radio"
                      id={`radio-button-${index}`}
                      checked={checkedValue === index}
                      onChange={() => {
                        !voted && setCheckedValue(index);
                      }}
                    />
                    <span className="radio-checkmark"></span>
                  </>
                ) : (
                  ''
                )}
              </label>

              {showResults ? (
                <>
                  <span className="radio-label">
                    {' - '} {Math.round(option.percentage * 100) / 100}%
                  </span>

                  <ProgressBar
                    className={classNames('mb-16', {
                      result: governancePollItem.result === index,
                    })}
                    now={option.percentage}
                  />
                </>
              ) : (
                ''
              )}
            </div>
          );
        })}
      </div>
      {!voted && isActive && !showResults ? (
        <div className="flex-container justify-end governance-poll-body-button-wrapper">
          <ButtonWithSpinner
            text="Sign &amp; Send"
            classes="btn-style-two"
            onClick={() => onVote(checkedValue)}
            loading={loading}
            disabled={loading || checkedValue === null}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default GovernancePollBody;
