import classNames from 'classnames';
import { GovernancePollItem } from 'models';
import React from 'react';

export interface GovernancePollHeaderProps {
  governancePollItem: GovernancePollItem;
  isActive: boolean;
  isExpanded: boolean;
  index: number;
}

const GovernancePollHeader: React.FC<GovernancePollHeaderProps> = ({
  governancePollItem,
  isActive,
  isExpanded,
  index,
}) => {
  return (
    <div className="governance-poll-header">
      <div className="flex-container justify-between align-center">
        <div>
          {isActive && (
            <i
              className={classNames('accordion-icon fas fa-chevron-down', {
                rotated: isExpanded,
              })}
            ></i>
          )}
          Voting #{index + 1} - {governancePollItem.title}
        </div>
        <div>
          {governancePollItem.voted ? <i className="fas fa-check"></i> : ''}
        </div>
      </div>
    </div>
  );
};

export default GovernancePollHeader;
