import { terraConfig } from 'config';
import Web3 from 'web3';

import { LCDClient, MsgExecuteContract, TxInfo } from '@terra-money/terra.js';
import { ConnectedWallet } from '@terra-money/wallet-provider';

import { terraExecuteContract } from './transaction-terra.helper';

export const linkEvmAddress = async (
  terraAddress: string,
  evmAddress: string,
  terraConnectedWallet: ConnectedWallet,
  terra: LCDClient
) => {
  const claimMsg: MsgExecuteContract = new MsgExecuteContract(
    terraAddress,
    terraConfig.additionalData.evnLinkContractAddress,
    {
      register_address: {
        address: evmAddress,
      },
    }
  );
  const tx: TxInfo = await terraExecuteContract(
    terraConnectedWallet,
    terra,
    claimMsg,
    terraAddress
  );

  return tx;
};

export const checkEvmAddressLinkStatus = async (
  address: string,
  terra: LCDClient
) => {
  const response: any = await terra.wasm.contractQuery(
    terraConfig.additionalData.evnLinkContractAddress,
    {
      get_data: {
        address,
      },
    }
  );

  const valid = isValidEvmAddress(response.data);

  return {
    linked: valid,
    address: valid ? response.data : undefined,
  };
};

export const isValidEvmAddress = (address: string) => {
  return Web3.utils.isAddress(address);
};
