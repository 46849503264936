import React from 'react';

interface PercentageButtonsProps {
  variant?: string;
  disabled?: boolean;
  applyPercentage: (percentage: number) => void;
}

const percentages = [25, 50, 75, 100];

export const PercentageButtons: React.FC<PercentageButtonsProps> = ({
  variant,
  disabled,
  applyPercentage,
}) => {
  const percentageButtons = percentages.map((percentage, key) => (
    <div
      className={`playnity-btn-wrapper ${variant} ${
        disabled ? 'disabled' : ''
      }`}
      key={key}
      onClick={() => {
        !disabled && onApplyPercentage(percentage);
      }}
    >
      <button
        className="btn playnity-btn-outline percentage-button"
        disabled={disabled}
      >
        {`${percentage}%`}
      </button>
    </div>
  ));

  const onApplyPercentage = (percentage: number) => {
    applyPercentage(percentage);
  };

  return <div className="percentage-buttons">{percentageButtons}</div>;
};
