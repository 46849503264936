import { HallOfFameRecord } from 'models';
import React from 'react';

import HallOfFameItem from './HallOfFameItem';

export interface HallOfFameItemsProps {
  hallOfFameItems: HallOfFameRecord[];
}

const HallOfFameItems: React.FC<HallOfFameItemsProps> = ({
  hallOfFameItems,
}) => {
  const items = hallOfFameItems.map(
    (hallOfFameItem: HallOfFameRecord, index: number) => (
      <HallOfFameItem
        hallOfFameItem={hallOfFameItem}
        key={`hall-of-fame-item-${index}`}
      />
    )
  );

  return <div className="hall-of-fame-items">{items}</div>;
};

export default HallOfFameItems;
