import { createTheme } from '@material-ui/core/styles';

const globalTheme = createTheme({
  palette: {
    primary: {
      main: '#2043b5',
    },
    secondary: {
      main: '#0044ff',
    },
    info: {
      main: '#e8eaed',
      dark: '#ffffff',
    },
  },
});

const theme = createTheme(
  {
    overrides: {
      MuiPaper: {
        root: {
          color: globalTheme.palette.secondary.main,
          backgroundColor: globalTheme.palette.info.dark,
          border: `3px solid ${globalTheme.palette.primary.main}`,
        },
        rounded: {
          borderRadius: '20px',
        },
      },
      MuiButton: {
        contained: {
          '&.Mui-disabled': {
            color: globalTheme.palette.primary.main,
            opacity: 0.5,
          },
        },
        containedPrimary: {
          height: '34px',
          borderRadius: '21px',
          fontSize: '13px',
          textTransform: 'initial',
          fontWeight: 'bold',
          '&:hover': {
            backgroundColor: globalTheme.palette.primary.main,
          },
        },
        outlinedPrimary: {
          border: `4px solid ${globalTheme.palette.primary.main}`,
          borderRadius: '21px',
          '&:hover': {
            border: `4px solid ${globalTheme.palette.primary.main}`,
          },
        },
        outlinedSecondary: {
          borderRadius: '21px',
          marginBottom: '15px',
          backgroundColor: globalTheme.palette.info.main,
          textTransform: 'initial',
          border: 'none',
          '&:hover': {
            border: 'none',
            backgroundColor: globalTheme.palette.primary.main,
            color: globalTheme.palette.info.main,
          },
        },
      },

      MuiInputBase: {
        input: {
          backgroundColor: globalTheme.palette.info.dark,
          color: globalTheme.palette.secondary.main,
          border: `3px solid ${globalTheme.palette.primary.main}`,
        },
      },
      MuiFormLabel: {
        root: {
          color: globalTheme.palette.secondary.main,
        },
      },
    },
  },
  globalTheme
);
export default theme;
