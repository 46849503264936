import { UserExperienceData } from 'models';
import { ExperienceState, State } from 'store';

import { createSelector } from '@reduxjs/toolkit';

const selectUserExperience = (state: State): ExperienceState =>
  state.experience;

export const selectUserExperienceData = createSelector(
  [selectUserExperience],
  (experienceState: ExperienceState): UserExperienceData =>
    experienceState?.userExperienceData
);
