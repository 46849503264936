import { AppChain, terraExecuteContract } from 'helpers';
import { StakingPoolItemData } from 'models';

import { LCDClient, MsgExecuteContract, TxInfo } from '@terra-money/terra.js';
import { ConnectedWallet } from '@terra-money/wallet-provider';

export const postMoveBond = async (
  sourceStakingPoolItem: StakingPoolItemData,
  destinationStakingPoolItem: StakingPoolItemData,
  connectedAddress: string,
  terraConnectedWallet: ConnectedWallet,
  terra: LCDClient
) => {
  switch (process.env.REACT_APP_CHAIN) {
    case AppChain.Bsc:
    case AppChain.Ethereum:
      return await postEvmMoveBond(
        sourceStakingPoolItem,
        destinationStakingPoolItem,
        connectedAddress
      );

    case AppChain.Terra:
      return await postTerraMoveBond(
        sourceStakingPoolItem,
        destinationStakingPoolItem,
        connectedAddress,
        terraConnectedWallet,
        terra
      );

    default:
      throw new Error('Unsupported Chain');
  }
};

const postEvmMoveBond = async (
  sourceStakingPoolItem: StakingPoolItemData,
  destinationStakingPoolItem: StakingPoolItemData,
  connectedAddress: string
): Promise<unknown> => {
  return await sourceStakingPoolItem.stakingPoolItemContract.methods
    .moveStakeAndClaim(
      destinationStakingPoolItem.stakingPoolItemContract.options.address
    )
    .send({
      from: connectedAddress,
    });
};

const postTerraMoveBond = async (
  sourceStakingPoolItemData: StakingPoolItemData,
  destinationStakingPoolItem: StakingPoolItemData,
  connectedAddress: string,
  terraConnectedWallet: ConnectedWallet,
  terra: LCDClient
): Promise<TxInfo> => {
  const moveBondMessage: MsgExecuteContract = new MsgExecuteContract(
    connectedAddress,
    sourceStakingPoolItemData.contractAddress,
    {
      move_bond: {
        destination_contract: destinationStakingPoolItem.contractAddress,
      },
    }
  );
  const tx: TxInfo = await terraExecuteContract(
    terraConnectedWallet,
    terra,
    moveBondMessage,
    connectedAddress
  );

  return tx;
};
