import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import { ButtonWithSpinner, Message } from 'components';
import { getStakingPoolItemData, MessageType } from 'helpers';
import {
  StakingPoolItemData,
  StakingPoolItemStakingData,
  StakingPoolType,
  TokenData,
} from 'models';
import React, { useState } from 'react';
import { Spinner, Tab } from 'react-bootstrap';

import { LCDClient } from '@terra-money/terra.js';

interface StakingPoolItemMoveBondTabContentProps {
  loading: boolean;
  staking: StakingPoolItemStakingData;
  connectedAddress: string;
  stakingPoolItemsData: StakingPoolItemData[];
  stakingTokenData: TokenData;
  moveBondDestinationStakingPoolItems: StakingPoolItemData[];
  stakingPoolType: StakingPoolType;
  terra: LCDClient;
  onMoveBond: (destinationPoolItemData: StakingPoolItemData) => void;
}

export const StakingPoolItemMoveBondTabContent: React.FC<
  StakingPoolItemMoveBondTabContentProps
> = ({
  loading,
  staking,
  connectedAddress,
  stakingPoolItemsData,
  stakingTokenData,
  moveBondDestinationStakingPoolItems,
  stakingPoolType,
  terra,
  onMoveBond,
}) => {
  const [selectedItem, setSelectedItem] = useState<StakingPoolItemData>(null);
  const [error, setError] = useState<string>(null);
  const [stablecoinDestinationPoolDataLoading, setDestinationPoolDataLoading] =
    useState<boolean>(null);
  const totalAplicableTokensBn = new BigNumber(staking.stakedTokens).plus(
    new BigNumber(staking.totalInUnbonding)
  );

  const checkStablecoinDestinationPoolItemLimit = async (
    selectedPoolItemData: StakingPoolItemData
  ) => {
    setDestinationPoolDataLoading(true);
    setError(null);

    try {
      const selectedPoolItemStakingData: StakingPoolItemStakingData =
        await getStakingPoolItemData(
          selectedPoolItemData,
          stakingPoolItemsData,
          stakingTokenData,
          connectedAddress,
          terra
        );

      const maxStakeReached =
        +selectedPoolItemStakingData?.maxStake &&
        new BigNumber(selectedPoolItemStakingData.maxStake)
          .minus(new BigNumber(staking.stakedTokens))
          .minus(selectedPoolItemStakingData.stakedTokens)
          .minus(new BigNumber(staking.totalInUnbonding))
          .isLessThan(0);

      if (maxStakeReached) {
        setError('Moving your bond will exceed target staking pool limit');
      }
    } catch (e) {
      setError(e as string);
    }
    setDestinationPoolDataLoading(false);
  };

  const onSelectPoolItem = (selectedPoolItemData: StakingPoolItemData) => {
    if (stakingPoolType === StakingPoolType.SC) {
      checkStablecoinDestinationPoolItemLimit(selectedPoolItemData);
    }

    setSelectedItem(selectedPoolItemData);
  };

  const moveBondButtonDisabled =
    loading || stablecoinDestinationPoolDataLoading || !selectedItem || !!error;

  return (
    <Tab.Pane eventKey="movebond" unmountOnExit={true} mountOnEnter={true}>
      <div className="tab-content">
        <div className="move-bond-section">
          <div className="move-bond-section-description">
            Please select destination from available staking pool items. All
            your staked and bonded tokens will be moved to the selected staking
            pool. If you have any rewards, they will be claimed at the same
            time.
          </div>
          {stakingPoolType === StakingPoolType.SC ? (
            <div className="move-bond-section-description">
              You can only move bond if you won&rsquo;t exceed the limit in the
              target pool
            </div>
          ) : (
            ''
          )}

          <div className="move-bond-section-actions">
            <div className="flex-container flex-column justify-between">
              {moveBondDestinationStakingPoolItems.map((item, index) => {
                const id = `move-bond-radio-${stakingPoolType
                  .replaceAll(' ', '-')
                  .toLowerCase()}-${item.poolItemName
                  .replaceAll(' ', '-')
                  .toLowerCase()}-${index}`;

                return (
                  <div key={id}>
                    <label
                      className={classNames('radio-label', {
                        disabled: stablecoinDestinationPoolDataLoading,
                      })}
                    >
                      {item.poolItemName}
                      <input
                        className="radio-input"
                        type="radio"
                        id={id}
                        checked={
                          item.poolItemName === selectedItem?.poolItemName
                        }
                        disabled={stablecoinDestinationPoolDataLoading}
                        onChange={() => onSelectPoolItem(item)}
                      />
                      <span className="radio-checkmark"></span>
                      {stablecoinDestinationPoolDataLoading ? (
                        <Spinner
                          className="ml-8"
                          animation="border"
                          role="status"
                          size="sm"
                        />
                      ) : (
                        ''
                      )}
                    </label>
                  </div>
                );
              })}
            </div>

            <ButtonWithSpinner
              classes="btn-style-three move-bond-section-btn"
              onClick={() => onMoveBond(selectedItem)}
              disabled={moveBondButtonDisabled}
              loading={loading}
            >
              Move {totalAplicableTokensBn.toString(10)}{' '}
              {stakingTokenData.symbol}
            </ButtonWithSpinner>
          </div>
        </div>
      </div>

      {error ? (
        <Message messageType={MessageType.Error} descriptionText={error} />
      ) : (
        ''
      )}
    </Tab.Pane>
  );
};
