import { GovernancePollItem } from 'models';
import React from 'react';
import { Accordion } from 'react-bootstrap';
import Web3 from 'web3';

import GovernancePoll from './GovernancePoll';

export interface GovernancePollsProps {
  governancePolls: GovernancePollItem[];
  connectedAddress: string;
  web3: Web3;
}

const GovernancePolls: React.FC<GovernancePollsProps> = ({
  governancePolls,
  connectedAddress,
  web3,
}) => {
  const polls = governancePolls?.map(
    (governancePollItem: GovernancePollItem, index: number) => {
      return (
        <GovernancePoll
          governancePollItem={governancePollItem}
          key={`governance-poll-component-${index}`}
          index={index}
          connectedAddress={connectedAddress}
          web3={web3}
        />
      );
    }
  );

  return <Accordion defaultActiveKey="0">{polls}</Accordion>;
};

export default GovernancePolls;
