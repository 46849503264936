import { HallOfFameRecord } from 'models';

import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';

export interface HallOfFameState {
  hallOfFameItems: HallOfFameRecord[];
}

export const intialHallOfFameState: HallOfFameState = {
  hallOfFameItems: null,
};

export const hallOfFameSlice = createSlice<
  HallOfFameState,
  SliceCaseReducers<HallOfFameState>,
  string
>({
  name: 'hallOfFame',
  initialState: intialHallOfFameState,
  reducers: {
    setHallOfFameItems: (state, action: PayloadAction<HallOfFameRecord[]>) => {
      state.hallOfFameItems = action.payload;
    },
  },
});
