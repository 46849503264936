import { roundNumber } from 'helpers';
import React, { PropsWithChildren } from 'react';
import { Spinner } from 'react-bootstrap';

interface DataItemProps {
  label: string;
  symbol?: string;
  data?: string;
  classes?: string;
}

export const DataItem: React.FC<PropsWithChildren<DataItemProps>> = ({
  label,
  symbol,
  data,
  classes,
  children,
}) => {
  const spinner = !children && (data == null || data == undefined) && (
    <Spinner animation="border" role="status" variant="light" size="sm" />
  );

  return (
    <div className={`data-item ${classes ?? 'align-center'}`}>
      <div className="data-item-header">{label}</div>
      <div className="data-item-data">
        {spinner}
        {data && (
          <span>
            {roundNumber(data)} {symbol}
          </span>
        )}
        {!data && children}
      </div>
    </div>
  );
};
