import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import {
  AppChain,
  getPoolHeaderData,
  isChain,
  isProduction,
  parseCurrency,
} from 'helpers';
import {
  StakingPoolItemData,
  StakingPoolItemHeaderData,
  StakingPoolItemStakingData,
  StakingPoolType,
  TokenData,
} from 'models';
import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';

interface StakingPoolItemHeaderProps {
  stakingPoolItemData: StakingPoolItemData;
  isActive: boolean;
  staking: StakingPoolItemStakingData;
  isExpanded: boolean;
  tokenPrice: string;
  stableTokenPrice: string;
  tokensInLpPair: string;
  totalLp: string;
  stakingPoolType: StakingPoolType;
  stakingTokenData: TokenData;
}

export const StakingPoolItemHeader: React.FC<StakingPoolItemHeaderProps> = ({
  stakingPoolItemData,
  isActive,
  staking,
  isExpanded,
  tokenPrice,
  stableTokenPrice,
  tokensInLpPair,
  totalLp,
  stakingPoolType,
  stakingTokenData,
}) => {
  const [poolHeaderData, setPoolHeaderData] =
    useState<StakingPoolItemHeaderData>();

  useEffect(() => {
    if (staking) {
      const poolHeaderData: StakingPoolItemHeaderData = getPoolHeaderData(
        tokenPrice,
        stableTokenPrice,
        tokensInLpPair,
        totalLp,
        staking.totalValueLocked,
        staking.rewardRate,
        stakingPoolType
      );

      setPoolHeaderData({
        tvlDolars: new BigNumber(poolHeaderData.tvlDolars).toFixed(2),
        apr: new BigNumber(poolHeaderData.apr).toFixed(2),
        apy: new BigNumber(poolHeaderData.apy).toFixed(2),
      });
    }
  }, [staking]);
  const anchorApyInfo =
    stakingPoolType &&
    stakingPoolType === StakingPoolType.SC &&
    isChain(AppChain.Terra)
      ? ' + 19% Anchor'
      : '';

  return (
    <div className="staking-pool-item-header">
      {poolHeaderData ? (
        <>
          <div className="name">
            {isActive && (
              <i
                className={classNames('accordion-icon fas fa-chevron-down', {
                  rotated: isExpanded,
                })}
              ></i>
            )}
            {stakingPoolItemData.poolItemName}
          </div>
          <div className="apy">
            <div>
              est. APY {poolHeaderData.apy}% {anchorApyInfo}
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-disabled">
                    Annual Percentage Yield with monthly compounding
                  </Tooltip>
                }
              >
                <i
                  className="far fa-question-circle apy-question-icon"
                  onClick={(event) => event.stopPropagation()}
                ></i>
              </OverlayTrigger>
            </div>
            {+staking.maxStake ? (
              <div className="apy-limit">
                limit {staking.maxStake} {stakingTokenData.symbol}*
              </div>
            ) : (
              ''
            )}
            {!isProduction() && (
              <small>[DEV_ONLY: APR {poolHeaderData.apr}%]</small>
            )}
          </div>
          <div className="total">
            TVL: {parseCurrency(poolHeaderData.tvlDolars)}
          </div>
        </>
      ) : (
        <div className="spinner-wrapper">
          <Spinner animation="border" role="status" variant="light" size="sm" />
        </div>
      )}
    </div>
  );
};
