import { asyncSleep } from 'helpers';

import { TxResult } from '@starterra/starterra-tool-dapp';
import {
  Account,
  Coin,
  Coins,
  CreateTxOptions,
  Fee,
  LCDClient,
  MsgExecuteContract,
  TxInfo,
} from '@terra-money/terra.js';
import { ConnectedWallet } from '@terra-money/wallet-provider';

const terraWaitForTxInfo = async (
  terra: LCDClient,
  txhash: string,
  milliseconds = 4000
): Promise<TxInfo> => {
  let tx: TxInfo;

  do {
    try {
      tx = await terra.tx.txInfo(txhash);

      if (tx) {
        return tx;
      }
      // eslint-disable-next-line no-empty
    } catch (err) {}

    await asyncSleep(milliseconds);
    // eslint-disable-next-line no-constant-condition
  } while (true);
};

export const terraExecuteContract = async (
  terraConnectedWallet: ConnectedWallet,
  terra: LCDClient,
  msg: MsgExecuteContract,
  address: string,
  memo?: string
): Promise<TxInfo> => {
  if (terraConnectedWallet.availablePost) {
    const createTxOptions: CreateTxOptions = {
      msgs: [msg],
      memo,
      gasPrices: new Coins([new Coin('uusd', 1)]), // reference gas prices: https://fcd.terra.dev/v1/txs/gas_prices
      gasAdjustment: 2.5,
      feeDenoms: ['uusd'],
    };
    const accountInfo: Account = await terra.auth.accountInfo(address);
    const fee: Fee = await terra.tx.estimateFee(
      [
        {
          sequenceNumber: accountInfo.getSequenceNumber(),
          publicKey: accountInfo.getPublicKey(),
        },
      ],
      createTxOptions
    );
    // manually add stability fee to exact gas calculation (gasPrices = 0.15uusd, gasAdjustment >= 1)
    // fee.amount.set('uusd', fee.amount.get('uusd').add(new Coin('uusd', 12000))); // gasPrices 0.15uusd, gasAdjustment 1.0
    const txResult: TxResult = await terraConnectedWallet.post({
      fee,
      msgs: [msg],
    });
    const txInfo: TxInfo = await terraWaitForTxInfo(
      terra,
      txResult.result.txhash,
      4000
    );

    return txInfo;
  }
};
