import { HallOfFameRecord } from 'models';
import { State } from 'store';
import { HallOfFameState } from 'store/reducers';

import { createSelector } from '@reduxjs/toolkit';

const selectHallOfFame = (state: State): HallOfFameState => state.hallOfFame;

export const selectHallOfFameItems = createSelector(
  [selectHallOfFame],
  (hallOfFameState: HallOfFameState): HallOfFameRecord[] =>
    hallOfFameState?.hallOfFameItems
);
