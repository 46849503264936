import { AppChain, fromWei, getContract, playnityConfig } from 'helpers';
import { PairData, TokenData } from 'models';
import { Dispatch } from 'react';
import { lpPairActions } from 'store';
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';

import { Action } from '@reduxjs/toolkit';
import { LCDClient } from '@terra-money/terra.js';

export const getLpPairContracts = (
  networkId: string,
  web3?: Web3,
  gasPrice?: string
): ((dispatch: Dispatch<Action>) => void) => {
  switch (process.env.REACT_APP_CHAIN) {
    case AppChain.Bsc:
    case AppChain.Ethereum:
      return getLpPairEvmContract(networkId, web3, gasPrice);
    case AppChain.Terra:
      return getLpPairTerraContract(networkId);
  }
};

const getLpPairEvmContract = (
  networkId: string,
  web3: Web3,
  gasPrice: string
): ((dispatch: Dispatch<Action>) => void) => {
  return (dispatch: Dispatch<Action>) => {
    try {
      const pairContract = getContract(
        web3,
        playnityConfig.LPPair.contractJSON,
        networkId,
        gasPrice
      );

      dispatch(
        lpPairActions.setLPPairData({
          pairContract,
          contractAddress: null,
        })
      );
    } catch (e) {
      console.log(e);
    }
  };
};

const getLpPairTerraContract = (
  networkId: string
): ((dispatch: Dispatch<Action>) => void) => {
  return (dispatch: Dispatch<Action>) => {
    try {
      const contractAddress = (
        playnityConfig.LPPair.contractJSON.networks as any
      )[networkId]?.address;

      dispatch(
        lpPairActions.setLPPairData({
          pairContract: null,
          contractAddress,
        })
      );
    } catch (e) {
      console.log(e);
    }
  };
};

export const getTokensInLpPair = (
  tokenData: TokenData,
  lpTokenData: TokenData,
  lpPairData: PairData,
  terra?: LCDClient
): ((dispatch: Dispatch<Action>) => void) => {
  switch (process.env.REACT_APP_CHAIN) {
    case AppChain.Bsc:
    case AppChain.Ethereum:
      return getTokensInEvmLpPair(
        tokenData,
        lpTokenData,
        lpPairData.pairContract
      );
    case AppChain.Terra:
      return getTokensInTerraLpPair(
        tokenData,
        lpPairData.contractAddress,
        terra
      );
  }
};

export const getTokensInEvmLpPair = (
  tokenData: TokenData, // PLY Token,
  lpTokenData: TokenData,
  lpPairContract: Contract
): ((dispatch: Dispatch<Action>) => void) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      const totalLPBN = await lpPairContract.methods.totalSupply().call();
      const totalLP = fromWei(totalLPBN, lpTokenData.decimals);
      const reserves = await lpPairContract.methods.getReserves().call();
      const token0Address: string = await lpPairContract.methods
        .token0()
        .call();
      // ? First token in pair is PLY token, needed for testnet pancake
      const reserve0 =
        token0Address.toLocaleLowerCase() ===
        tokenData.tokenContract.options.address.toLocaleLowerCase()
          ? reserves._reserve0
          : reserves._reserve1;
      const tokensInLpPair = fromWei(reserve0, tokenData.decimals);

      dispatch(lpPairActions.setTokensInLpPair(tokensInLpPair));
      dispatch(lpPairActions.setTotalLp(totalLP));
    } catch (e) {
      console.log(e);
    }
  };
};

export const getTokensInTerraLpPair = (
  tokenData: TokenData,
  lpPairContractAddress: string,
  terra: LCDClient
): ((dispatch: Dispatch<Action>) => void) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    const getData = async () => {
      const poolMsg = {
        pool: {},
      };
      const poolResponse: {
        assets: { amount: string }[];
        total_share: string;
      } = await terra.wasm.contractQuery(lpPairContractAddress, poolMsg);

      return {
        amount: poolResponse.assets[0].amount,
        totalLP: poolResponse.total_share,
      };
    };

    try {
      const data = await getData();

      const tokensInLpPairWei = data.amount;
      const tokensInLpPair = fromWei(tokensInLpPairWei, tokenData.decimals);
      const totalLPWei = data.totalLP;
      const totalLP = fromWei(totalLPWei, tokenData.decimals);

      dispatch(lpPairActions.setTokensInLpPair(tokensInLpPair));
      dispatch(lpPairActions.setTotalLp(totalLP));
    } catch (e) {
      console.log(e);
    }
  };
};
