import { isDevelopment } from 'helpers';

import { combineReducers, configureStore } from '@reduxjs/toolkit';

import {
  allocationSlice,
  AllocationState,
  appSlice,
  AppState,
  experienceSlice,
  ExperienceState,
  governanceSlice,
  GovernanceState,
  hallOfFameSlice,
  HallOfFameState,
  leaderboardSlice,
  LeaderboardState,
  lpPairSlice,
  LpPairState,
  stablePairSlice,
  StablePairState,
  stakingSlice,
  StakingState,
  tokenSlice,
  TokenState,
  vestingSlice,
  VestingState,
} from './reducers';

export interface State {
  app: AppState;
  token: TokenState;
  staking: StakingState;
  allocation: AllocationState;
  vesting: VestingState;
  lpPair: LpPairState;
  stablePair: StablePairState;
  leaderboard: LeaderboardState;
  experience: ExperienceState;
  hallOfFame: HallOfFameState;
  governance: GovernanceState;
}

export const appActions = appSlice.actions;
export const stakingActions = stakingSlice.actions;
export const allocationActions = allocationSlice.actions;
export const vestingActions = vestingSlice.actions;
export const tokenActions = tokenSlice.actions;
export const lpPairActions = lpPairSlice.actions;
export const stablePairActions = stablePairSlice.actions;
export const leaderboardActions = leaderboardSlice.actions;
export const experienceActions = experienceSlice.actions;
export const hallOfFameActions = hallOfFameSlice.actions;
export const governanceActions = governanceSlice.actions;

export const store = configureStore({
  reducer: combineReducers({
    app: appSlice.reducer,
    staking: stakingSlice.reducer,
    vesting: vestingSlice.reducer,
    allocation: allocationSlice.reducer,
    token: tokenSlice.reducer,
    lpPair: lpPairSlice.reducer,
    stablePair: stablePairSlice.reducer,
    leaderboard: leaderboardSlice.reducer,
    experience: experienceSlice.reducer,
    hallOfFame: hallOfFameSlice.reducer,
    governance: governanceSlice.reducer,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
  // ? Causes errors in console
  devTools: isDevelopment(),
});

export * from './actions';
export * from './selectors';
export * from './reducers';
