import { SectionLoader } from 'components';
import {
  AllocationPoolData,
  AllocationPoolItemData,
  AllocationPoolType,
  TokenData,
} from 'models';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectAllocationPools,
  selectBalance,
  selectLeftSDT,
  selectStablecoinBalance,
  selectStableTokenData,
  selectTokenData,
} from 'store';

import AllocationPool from './AllocationPool';

const AllocationPools: React.FC = () => {
  const balance: string = useSelector(selectBalance);
  const stablecoinBalance: string = useSelector(selectStablecoinBalance);
  const tokenData: TokenData = useSelector(selectTokenData);
  const stableTokenData: TokenData = useSelector(selectStableTokenData);
  const allocationPoolsData: AllocationPoolData[] = useSelector(
    selectAllocationPools
  );
  const leftSDT = useSelector(selectLeftSDT);

  const getAllocationTokenData = (
    allocationPoolData: AllocationPoolData
  ): TokenData => {
    switch (allocationPoolData.poolType) {
      case AllocationPoolType.SA:
        return tokenData;
      case AllocationPoolType.SC:
        return stableTokenData;
    }
  };

  const getPoolTokenBalance = (
    allocationPoolData: AllocationPoolData
  ): string => {
    switch (allocationPoolData.poolType) {
      case AllocationPoolType.SA:
        return balance;
      case AllocationPoolType.SC:
        return stablecoinBalance;
    }
  };

  const allocationPools = allocationPoolsData
    ?.reduce(
      (acc: AllocationPoolData[], allocationPoolData: AllocationPoolData) => {
        const pool = {
          ...allocationPoolData,
          poolItems: allocationPoolData.poolItems.filter(
            (poolItem: AllocationPoolItemData) => poolItem.isActive
          ),
        };

        if (pool.poolItems?.length) {
          acc.push(pool);
        }

        return acc;
      },
      []
    )
    ?.map((allocationPoolData: AllocationPoolData, index: number) => {
      return (
        <AllocationPool
          key={`staking-pool-component-${index}`}
          allocationPoolData={allocationPoolData}
          tokenData={tokenData}
          stablecoinTokenData={stableTokenData}
          allocationTokenData={getAllocationTokenData(allocationPoolData)}
          balance={getPoolTokenBalance(allocationPoolData)}
          leftSDT={leftSDT}
        />
      );
    });

  const inactiveAllocationPools = allocationPoolsData
    ?.reduce(
      (acc: AllocationPoolData[], allocationPoolData: AllocationPoolData) => {
        const pool = {
          ...allocationPoolData,
          poolItems: allocationPoolData.poolItems.filter(
            (poolItem: AllocationPoolItemData) => !poolItem.isActive
          ),
        };

        if (pool.poolItems?.length) {
          acc.push(pool);
        }

        return acc;
      },
      []
    )
    ?.map((allocationPoolData: AllocationPoolData, index: number) => {
      return (
        <AllocationPool
          key={`staking-pool-component-inactive-${index}`}
          allocationPoolData={allocationPoolData}
          tokenData={tokenData}
          stablecoinTokenData={stableTokenData}
          allocationTokenData={getAllocationTokenData(allocationPoolData)}
          balance={getPoolTokenBalance(allocationPoolData)}
          leftSDT={leftSDT}
        />
      );
    });

  return allocationPools ? (
    <div className="staking-pools">
      {tokenData && !!allocationPools?.length && (
        <>
          <div className="text-center mb-24 mt-24">
            <div style={{ fontSize: '24px', color: 'lime' }}>
              Active sale rounds
            </div>
          </div>

          <>{allocationPools}</>
        </>
      )}

      {tokenData && !!inactiveAllocationPools?.length && (
        <>
          <div className="text-center mb-24 mt-24">
            <div style={{ fontSize: '24px', color: '#8B0000' }}>
              Completed rounds
            </div>
          </div>

          <>{inactiveAllocationPools}</>
        </>
      )}

      {/* {isProduction() ? (
        ''
      ) : leftSDT ? (
        <div className="text-center mb-24">
          <div style={{ fontSize: '24px' }}>SDT REMAINING</div>
          <div style={{ fontSize: '24px' }}>
            <span className="cyan">{parseInt(leftSDT)}</span> /{' '}
            {playnityConfig.allocation.allocationLimit}
          </div>
        </div>
      ) : (
        <div className="mb-24">
          <SectionLoader />
        </div>
      )} */}
    </div>
  ) : (
    <SectionLoader />
  );
};

export default AllocationPools;
