import { Contract } from 'web3-eth-contract';

import { ContractJSON } from './contract.model';

export enum PlaynityTokenName {
  Playnity = 'Playnity',
  LPPlaynity = 'LP-Playnity',
  LPPlaynityUSDC = 'LP-Playnity-USDC',
  Stablecoin = 'Stablecoin',
}

export enum TokenSymbol {
  PLY = 'PLY',
  'LPPLY' = 'LP',
  UST = 'aUST',
  // Used for allocation
  BUSD = 'BUSD',
  USDT = 'USDT',
}

export interface TokenData {
  name: PlaynityTokenName;
  symbol: TokenSymbol;
  decimals: number;
  tokenContract?: Contract;
  contractAddress?: string;
}

export interface TokenConfig {
  name: PlaynityTokenName;
  symbol: TokenSymbol;
  decimals: number;
  tokenContractJSON: ContractJSON;
}
