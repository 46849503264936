import { VestingPoolData } from 'models';

import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';

export interface VestingState {
  pools: VestingPoolData[];
}

export const initialVestingState: VestingState = {
  pools: null,
};

export const vestingSlice = createSlice<
  VestingState,
  SliceCaseReducers<VestingState>,
  string
>({
  name: 'vesting',
  initialState: initialVestingState,
  reducers: {
    setVestingPoolsData: (state, action: PayloadAction<VestingPoolData[]>) => {
      state.pools = action.payload;
    },
    resetVestingState: (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = initialVestingState;
    },
  },
});
