/* eslint-disable react-hooks/rules-of-hooks */
import { Layout, MainModal, Preloader } from 'components';
import Playnity from 'components/Playnity';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  AppChain,
  AppChainGroup,
  isChain,
  isPermittedNetwork,
  web3ModalAutoWalletConnect,
  web3ModalOptions,
} from 'helpers';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  appActions,
  getAllocationPoolsData,
  getLpPairContracts,
  getStablecoinPairContracts,
  getStableTokenPrices,
  getStakingPoolsData,
  getTokensContracts,
  getVestingPoolsData,
  selectConnectedAddress,
  selectShowLoader,
  selectShowModal,
  selectWeb3,
  selectWeb3Modal,
  selectWeb3Provider,
} from 'store';
import Web3 from 'web3';
import Web3Modal from 'web3modal';

import {
  useConnectedWallet,
  useWallet,
  WalletStatus,
} from '@starterra/starterra-tool-dapp';
import { LCDClient } from '@terra-money/terra.js';
import { ConnectedWallet, Wallet } from '@terra-money/wallet-provider';
import WalletConnectProvider from '@walletconnect/web3-provider';

dayjs.extend(utc);

// Route guards https://blog.netcetera.com/how-to-create-guarded-routes-for-your-react-app-d2fe7c7b6122
// https://redux-observable.js.org/docs/basics/Epics.html
const App: React.FC = () => {
  const dispatch = useDispatch();
  const showLoader = useSelector(selectShowLoader);
  const connectedAddress = useSelector(selectConnectedAddress);
  const showModal = useSelector(selectShowModal);

  // Evm specific fields
  let web3: Web3;
  let web3Modal: Web3Modal;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let web3Provider: any;

  // Terra specific fields
  let terraWallet: Wallet;
  let connectedWallet: ConnectedWallet;

  if (isChain(AppChainGroup.Evm)) {
    web3 = useSelector(selectWeb3);
    web3Provider = useSelector(selectWeb3Provider);
    web3Modal = useSelector(selectWeb3Modal);
  } else if (isChain(AppChain.Terra)) {
    terraWallet = useWallet();
    connectedWallet = useConnectedWallet();
  }

  useEffect(() => {
    if (isChain(AppChainGroup.Evm)) {
      const web3Modal = new Web3Modal(web3ModalOptions);
      dispatch(appActions.setWeb3Modal(web3Modal));
    }
  }, []);

  // Show web3modal on initial page load (or autoreconnenct if cached provider)
  // TODO: set conditionally by autoWalletConnect
  useEffect(() => {
    if (isChain(AppChainGroup.Evm)) {
      if (web3ModalAutoWalletConnect && web3Modal?.cachedProvider) {
        connectWallet();
      } else if (web3Modal && !web3Modal.cachedProvider) {
        dispatch(appActions.setShowLoader(false));
      }
    }
  }, [isChain(AppChainGroup.Evm) ? web3Modal : undefined]);

  useEffect(() => {
    if (isChain(AppChainGroup.Evm) && web3Provider) {
      initEvmChainData();
    }
  }, [web3Provider]);

  useEffect(() => {
    if (isChain(AppChain.Terra)) {
      // on connect terra wallet
      if (terraWallet?.status === WalletStatus.WALLET_CONNECTED) {
        initTerraChainData();
        // on disconnect terra wallet
      } else if (
        connectedAddress &&
        terraWallet?.status === WalletStatus.WALLET_NOT_CONNECTED
      ) {
        dispatch(appActions.setShowLoader(true));

        window.location.reload();
      } else {
        dispatch(appActions.setShowLoader(false));
      }
    }
  }, [terraWallet?.status]);

  const initEvmChainData = async () => {
    try {
      const web3 = new Web3(web3Provider);
      const gasPrice = await web3.eth.getGasPrice();
      const networkId: string = (await web3.eth.net.getId()).toString();
      const accounts = await web3.eth.getAccounts();

      web3Provider.on('accountsChanged', () => {
        window.location.reload();
      });
      web3Provider.on('chainChanged', () => {
        window.location.reload();
      });
      web3Provider.on(
        'disconnect',
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );

      dispatch(
        appActions.setInitialData({
          web3,
          gasPrice,
          connectedAddress: accounts[0],
          balance: null,
        })
      );

      if (isPermittedNetwork(networkId)) {
        dispatch(getStableTokenPrices());
        dispatch(getLpPairContracts(networkId, web3, gasPrice));
        dispatch(getStablecoinPairContracts(networkId));
        dispatch(getTokensContracts(networkId, web3, gasPrice));
        dispatch(
          getStakingPoolsData(networkId, web3, accounts[0], null, gasPrice)
        );
        dispatch(
          getVestingPoolsData(networkId, accounts[0], web3, null, gasPrice)
        );
        dispatch(
          getAllocationPoolsData(networkId, web3, accounts[0], gasPrice)
        );
      }

      dispatch(appActions.setInitializingFinished(true));
    } catch (error) {
      console.error(error);
    }

    dispatch(appActions.setShowLoader(false));
  };

  const initTerraChainData = async () => {
    if (terraWallet.status == WalletStatus.WALLET_CONNECTED) {
      try {
        const account = connectedWallet?.terraAddress.toString() ?? '';
        const networkId = terraWallet.network.chainID;
        const terra = new LCDClient({
          URL: terraWallet.network.lcd,
          chainID: networkId,
        });

        dispatch(
          appActions.setInitialData({
            connectedAddress: account,
            balance: null,
            terra,
          })
        );

        if (isPermittedNetwork(networkId)) {
          dispatch(getStableTokenPrices());
          dispatch(getLpPairContracts(networkId));
          dispatch(getStablecoinPairContracts(networkId));
          dispatch(getTokensContracts(networkId));
          dispatch(getStakingPoolsData(networkId, null, account, terra));
          dispatch(getVestingPoolsData(networkId, account, null, terra));
        }

        dispatch(appActions.setInitializingFinished(true));
      } catch (error) {
        console.error(error);
      }

      dispatch(appActions.setShowLoader(false));
    }
  };

  const connectWallet = async () => {
    try {
      const provider = await web3Modal.connect();
      dispatch(appActions.setWeb3Provider(provider));
    } catch (error) {
      console.error('ERROR@connectWallet:', error);
      dispatch(appActions.setShowLoader(false));
    }
  };

  const disconnectWallet = async () => {
    dispatch(appActions.setWeb3Provider(null));
    dispatch(appActions.setConnectedAddress(null));

    if (web3.currentProvider instanceof WalletConnectProvider) {
      await (web3.currentProvider as WalletConnectProvider).disconnect();
    }

    web3Modal.clearCachedProvider();
    window.location.reload();
  };

  return (
    <Fragment>
      {showLoader && <Preloader />}
      <Layout
        connectWallet={isChain(AppChainGroup.Evm) && connectWallet}
        disconnectWallet={isChain(AppChainGroup.Evm) && disconnectWallet}
      >
        <Playnity />
        {isChain(AppChain.Terra) ? (
          <MainModal
            showModal={showModal}
            hideModal={() => dispatch(appActions.setShowModal(false))}
          />
        ) : (
          ''
        )}
      </Layout>
    </Fragment>
  );
};

export default App;
