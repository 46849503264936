import { AppChain, playnityConfig } from 'helpers';
import { PermittedNetwork } from 'models';

import { AppChainGroup, evmChains } from './config.helper';

export enum NoConnectionType {
  WrongNetwork = 'wrong-network',
  WalletNotConnected = 'wallet-not-connected',
}

export const isPermittedNetwork = (networkId: string) => {
  return playnityConfig.permittedNetworks.some(
    (permittedNetwork: PermittedNetwork) => permittedNetwork === networkId
  );
};

export const isChain = (appChain: AppChain | AppChainGroup) => {
  const isEvmChain = evmChains.includes(
    process.env.REACT_APP_CHAIN as AppChain
  );

  return appChain === AppChainGroup.Evm
    ? isEvmChain
    : process.env.REACT_APP_CHAIN === appChain;
};

export const getChainName = (appChain: AppChain): string => {
  switch (appChain) {
    case AppChain.Bsc:
      return 'Binance Smart Chain';
    case AppChain.Terra:
      return 'Terra';
    case AppChain.Ethereum:
      return 'Ethereum';
    default:
      throw new Error('Unsupported Chain');
  }
};
