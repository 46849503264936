import { SectionDetails } from 'components';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectStableTokenPrices } from 'store';

import AllocationPools from './AllocationPools';

export const Allocation: React.FC = () => {
  const stableTokenPrices = useSelector(selectStableTokenPrices);
  const title = 'SDT pre-game sale';

  return (
    <div className="staking">
      <SectionDetails title={title}>
        <div>
          Early SkyNity $SDT token pre-game sale. Please note the limits shown
          below for your address.
        </div>
        {/* <div>
          Early SkyNity $SDT token pre-game sale for loyal $PLY hodlers with PEX
          points. Please note the limits shown below for your address.
        </div> */}
        {/* <div className="mt-24">
          Market $SDT price chart:
          <a
            href="https://www.geckoterminal.com/bsc/pools/0x7632626286c17475a91f39173c144cff9b115eca"
            className="link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-chart-line link-icon-right"></i>
          </a>
        </div> */}
        {/* <div className="mt-24" style={{ fontSize: 'smaller', opacity: 0.6 }}>
          Within the current sale round you will receive:
          <div>1 SDT for each 0.038 BUSD deposited</div>
          Your max. allocation is 1 BUSD per every 20 PEX points gathered.
        </div> */}
      </SectionDetails>

      {/* <div className="text-center">
        <AllocationInfoModal />
      </div> */}

      {stableTokenPrices ? <AllocationPools /> : ''}
    </div>
  );
};

export default Allocation;
