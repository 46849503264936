import { LeaderboardResponse } from 'models';
import { leaderboardActions } from 'store';

import { Action, Dispatch } from '@reduxjs/toolkit';

export const getLeaderboardData = (
  connectedAddress: string
): ((dispatch: Dispatch<Action>) => void) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      // const count = 200;
      const response: Response = await fetch(
        `${process.env.REACT_APP_API_URL}/leaderboard2?chain=${process.env.REACT_APP_CHAIN}&address=${connectedAddress}`
      );
      const { addresses, current }: LeaderboardResponse = await response.json();

      dispatch(leaderboardActions.setLeaderboardAddresses(addresses));
      dispatch(leaderboardActions.setLeaderboardCurrentAddressData(current));
    } catch (e) {
      console.log(e);
    }
  };
};
