import React from 'react';
import { Spinner } from 'react-bootstrap';

export const SectionLoader: React.FC = () => {
  return (
    <div className="section-loader">
      <Spinner animation="border" role="status" variant="light" size="sm" />
    </div>
  );
};
