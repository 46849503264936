import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

export const ExperienceInfoModal: React.FC = () => {
  const [showModal, setShowModal] = useState(false);

  const onCloseModal = () => setShowModal(false);
  const onShowModal = () => setShowModal(true);

  return (
    <>
      <a className="link pointer" onClick={onShowModal}>
        Show Rules <i className="far fa-question-circle pointer"></i>
      </a>

      <Modal
        show={showModal}
        onHide={onCloseModal}
        className="experience-info-modal"
      >
        <Modal.Header closeButton className="align-center">
          <Modal.Title>Experience rules</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-16">
            Pex points can be gathered in everyday snapshot. Additional social
            media tasks will be announced to gather Pex points
          </div>
          <div className="experience-info-modal-table">
            <div className="experience-info-modal-table-header">
              $PLY staked in Single Asset Staking - at least
            </div>
            <div className="experience-info-modal-table-item">
              <div>1 000 $PLY</div>
              <div className="font-bold cyan">1 Pex</div>
            </div>
            <div className="experience-info-modal-table-item">
              <div>3 000 $PLY</div>
              <div className="font-bold cyan">2 Pex</div>
            </div>
            <div className="experience-info-modal-table-item">
              <div>7 000 $PLY</div>
              <div className="font-bold cyan">3 Pex </div>
            </div>
            <div className="experience-info-modal-table-item">
              <div>15 000 $PLY</div>
              <div className="font-bold cyan">4 Pex </div>
            </div>
            <div className="experience-info-modal-table-item">
              <div>30 000 $PLY</div>
              <div className="font-bold cyan">6 Pex </div>
            </div>
            <div className="experience-info-modal-table-item">
              <div>100 000 $PLY</div>
              <div className="font-bold cyan">8 Pex </div>
            </div>
            <div className="experience-info-modal-table-item">
              <div>400 000 $PLY</div>
              <div className="font-bold cyan">11 Pex </div>
            </div>
            <div className="experience-info-modal-table-item">
              <div>1 000 000 $PLY</div>
              <div className="font-bold cyan">14 Pex </div>
            </div>
          </div>

          <div className="experience-info-modal-table">
            <div className="experience-info-modal-table-header">
              Liquidity Pool tokens staked containing at least
            </div>
            <div className="experience-info-modal-table-item">
              <div>1 000 $PLY</div>
              <div className="font-bold cyan">3 Pex</div>
            </div>
            <div className="experience-info-modal-table-item">
              <div>3 000 $PLY</div>
              <div className="font-bold cyan">5 Pex</div>
            </div>
            <div className="experience-info-modal-table-item">
              <div>7 000 $PLY</div>
              <div className="font-bold cyan">7 Pex</div>
            </div>
            <div className="experience-info-modal-table-item">
              <div>15 000 $PLY</div>
              <div className="font-bold cyan">10 Pex</div>
            </div>
            <div className="experience-info-modal-table-item">
              <div>30 000 $PLY</div>
              <div className="font-bold cyan">13 Pex </div>
            </div>
            <div className="experience-info-modal-table-item">
              <div>100 000 $PLY</div>
              <div className="font-bold cyan">18 Pex </div>
            </div>
            <div className="experience-info-modal-table-item">
              <div>400 000 $PLY</div>
              <div className="font-bold cyan">25 Pex </div>
            </div>
            <div className="experience-info-modal-table-item">
              <div>1 000 000 $PLY</div>
              <div className="font-bold cyan">36 Pex </div>
            </div>
          </div>

          <div className="experience-info-modal-table">
            <div className="experience-info-modal-table-header">
              Leaderboard position
            </div>
            <div className="experience-info-modal-table-item">
              <div>Top 3 addresses</div>
              <div className="font-bold cyan">30 Pex</div>
            </div>
            <div className="experience-info-modal-table-item">
              <div>Top 10 addresses</div>
              <div className="font-bold cyan">20 Pex</div>
            </div>
            <div className="experience-info-modal-table-item">
              <div>Top 30 addresses</div>
              <div className="font-bold cyan">10 Pex</div>
            </div>
            <div className="experience-info-modal-table-item">
              <div>Top 50 addresses</div>
              <div className="font-bold cyan">4 Pex </div>
            </div>
            <div className="experience-info-modal-table-item">
              <div>Top 100 addresses</div>
              <div className="font-bold cyan">2 Pex </div>
            </div>
            <div className="experience-info-modal-table-item">
              <div>Top 200 addresses</div>
              <div className="font-bold cyan">1 Pex </div>
            </div>
          </div>

          <div className="experience-info-modal-table">
            <div className="experience-info-modal-table-header">
              Loyalty bonus, staked value not reduced in the last
            </div>
            <div className="experience-info-modal-table-item">
              <div>4 days</div>
              <div className="font-bold cyan">Daily multiplier x2</div>
            </div>
            <div className="experience-info-modal-table-item">
              <div>8 days</div>
              <div className="font-bold cyan">Daily multiplier x3</div>
            </div>
            <div className="experience-info-modal-table-item">
              <div>15 days</div>
              <div className="font-bold cyan">Daily multiplier x5</div>
            </div>
            <div className="experience-info-modal-table-item">
              <div>30 days</div>
              <div className="font-bold cyan">Daily multiplier x8</div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
