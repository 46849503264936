import { SectionDetails, SectionLoader } from 'components';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getGovernancePolls,
  selectConnectedAddress,
  selectGovernancePolls,
  selectWeb3,
} from 'store';

import GovernancePolls from './GovernancePolls';

export const Governance: React.FC = () => {
  const dispatch = useDispatch();
  const governancePolls = useSelector(selectGovernancePolls);
  const connectedAddress = useSelector(selectConnectedAddress);
  const web3 = useSelector(selectWeb3);

  const title = 'Governance';

  useEffect(() => {
    if (connectedAddress) {
      getData();
    }
  }, [connectedAddress]);

  const getData = async () => {
    dispatch(getGovernancePolls(connectedAddress));
  };

  return (
    <div className="governance">
      <SectionDetails title={title}>
        <div>Vote using your $PLY tokens</div>
        <div>Take part in the project decisions</div>
        <div>
          Higher voting power multiplier can be gained by having Nft Genesis on
          address during snapshot
        </div>
      </SectionDetails>

      {governancePolls ? (
        <GovernancePolls
          governancePolls={governancePolls}
          connectedAddress={connectedAddress}
          web3={web3}
        />
      ) : (
        <SectionLoader />
      )}
    </div>
  );
};
