import { AppChain } from 'helpers';
import { PropsWithChildren } from 'react';

export const ConditionalTerraWalletProvider: React.FC<
  PropsWithChildren<{
    wrapper: any;
  }>
> = ({ wrapper, children }) => {
  switch (process.env.REACT_APP_CHAIN) {
    case AppChain.Bsc:
    case AppChain.Ethereum:
      return children;
    case AppChain.Terra:
      return wrapper(children);
  }
};
