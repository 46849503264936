import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import {
  AllocationPoolItemContent,
  AllocationPoolItemDetails,
  AllocationPoolItemHeader,
  Message,
} from 'components';
import {
  getAllocationPoolItemData,
  isCorrectValue,
  MAX_UINT256,
  MessageText,
  MessageType,
  postAllocate,
} from 'helpers';
import {
  AllocationPoolData,
  AllocationPoolItemAllocationData,
  AllocationPoolItemData,
  AllocationPoolType,
  TokenData,
  TokenSymbol,
} from 'models';
import React, { useEffect, useReducer } from 'react';
import { Accordion, Card, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { appActions, getBalance, selectConnectedAddress } from 'store';

import {
  AllocationPoolDetailsActions,
  allocationPoolDetailsReducer,
  initialAllocationPoolDetailsState,
} from '../store/reducer';

interface AllocationPoolItemProps {
  index: number;
  balance: string;
  allocationPoolData: AllocationPoolData;
  allocationPoolItemData: AllocationPoolItemData;
  allocationTokenData: TokenData;
  leftSDT: string;
  tokenData: TokenData;
  stablecoinTokenData: TokenData;
}

export const AllocationPoolItem: React.FC<AllocationPoolItemProps> = ({
  index,
  balance,
  allocationPoolData,
  allocationPoolItemData,
  allocationTokenData,
  leftSDT,
  tokenData,
  stablecoinTokenData,
}) => {
  const [state, dispatch] = useReducer(
    allocationPoolDetailsReducer,
    initialAllocationPoolDetailsState
  );
  const dispatchApp = useDispatch();
  const connectedAddress: string = useSelector(selectConnectedAddress);

  const getData = () => {
    getAllocationPoolDetailsData();
    dispatchApp(getBalance(tokenData, connectedAddress, undefined));
    dispatchApp(getBalance(stablecoinTokenData, connectedAddress, undefined));
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (state.allocation?.totalSupply) {
      setAllocationTvl();
    }
  }, [state.allocation]);

  useEffect(() => {
    if (state.loading === false) {
      getData();
    }
  }, [state.loading]);

  // TODO: TEMPORARY FUNCTION, FIND BETTER APPROACH
  const setAllocationTvl = () => {
    dispatchApp(
      appActions.setAllocationTvl({
        symbol: allocationTokenData.symbol,
        totalSupply: state.allocation.totalSupply,
      })
    );
  };

  const getAllocationPoolDetailsData = async () => {
    try {
      const allocationPoolItemAllocationData: AllocationPoolItemAllocationData =
        await getAllocationPoolItemData(
          allocationPoolItemData,
          allocationTokenData,
          connectedAddress
        );

      dispatch({
        type: AllocationPoolDetailsActions.SetAllocation,
        payload: allocationPoolItemAllocationData,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onApprove = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    dispatch({ type: AllocationPoolDetailsActions.SetLoading, payload: true });
    dispatch({
      type: AllocationPoolDetailsActions.SetErrorMessage,
      payload: '',
    });

    try {
      await allocationTokenData.tokenContract.methods
        .approve(
          allocationPoolItemData.poolItemContract.options.address,
          MAX_UINT256
        )
        .send({ from: connectedAddress });

      // TODO: Find better way for refresh
      dispatch({
        type: AllocationPoolDetailsActions.SetAllocationValue,
        payload: '',
      });
    } catch (err) {
      dispatch({
        type: AllocationPoolDetailsActions.SetErrorMessage,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        payload: (err as any).message,
      });
    }
    dispatch({ type: AllocationPoolDetailsActions.SetLoading, payload: false });
  };

  const onAllocate = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    dispatch({ type: AllocationPoolDetailsActions.SetLoading, payload: true });
    dispatch({
      type: AllocationPoolDetailsActions.SetErrorMessage,
      payload: '',
    });

    try {
      // TODO: Get current limit
      // getData();
      await postAllocate(
        allocationPoolItemData,
        state.allocationValue,
        allocationTokenData.decimals,
        connectedAddress
      );
    } catch (err) {
      dispatch({
        type: AllocationPoolDetailsActions.SetErrorMessage,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        payload: (err as any).message,
      });
    }

    dispatch({ type: AllocationPoolDetailsActions.SetLoading, payload: false });
    dispatch({
      type: AllocationPoolDetailsActions.SetAllocationValue,
      payload: '',
    });
  };

  const onAllocateValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = event.target.value;

    if (isCorrectValue(value, allocationTokenData.decimals)) {
      dispatch({
        type: AllocationPoolDetailsActions.SetAllocationValue,
        payload: value,
      });
    }
  };

  const onApplyPercentage = (percentage: number, value: string) => {
    const BNValue = new BigNumber(value);
    const roundedNumber = BNValue.times(percentage)
      .div(100)
      .toFixed(allocationTokenData.decimals);

    dispatch({
      type: AllocationPoolDetailsActions.SetAllocationValue,
      payload: roundedNumber,
    });
  };

  const stakingPoolItemClassNames = classNames(
    'staking-pool-item',
    'allocation-pool-item',
    {
      inactive:
        !allocationPoolItemData.addressCanAllocate ||
        !allocationPoolItemData.isActive,
    }
  );

  return (
    <Accordion className={stakingPoolItemClassNames} defaultActiveKey="0">
      <Card className={classNames({ loading: !state.allocation })}>
        <Accordion.Toggle
          as={Card.Header}
          eventKey={index.toString()}
          onClick={() => {
            allocationPoolItemData.addressCanAllocate &&
              allocationPoolItemData.isActive &&
              state.allocation &&
              dispatch({
                type: AllocationPoolDetailsActions.SetIsExpanded,
                payload: !state.isExpanded,
              });
          }}
        >
          <AllocationPoolItemHeader
            allocationPoolItemData={allocationPoolItemData}
            isExpanded={state.isExpanded}
          />
        </Accordion.Toggle>

        {allocationPoolItemData.addressCanAllocate && state.allocation && (
          <Accordion.Collapse
            eventKey={index.toString()}
            unmountOnExit={true}
            mountOnEnter={true}
          >
            <Card.Body>
              <div className="staking-pool-item-body">
                <AllocationPoolItemDetails
                  allocationPoolItemData={allocationPoolItemData}
                  balance={balance}
                  allocation={state.allocation}
                  symbol={
                    allocationPoolData.poolType === AllocationPoolType.SA
                      ? allocationTokenData.symbol
                      : TokenSymbol.BUSD
                  }
                />
                {allocationPoolItemData.addressCanAllocate &&
                  allocationPoolItemData.isActive && (
                    <Tab.Container
                      id="tabs-container"
                      defaultActiveKey="allocate"
                    >
                      <Tab.Content className="tabs">
                        <AllocationPoolItemContent
                          loading={state.loading}
                          balance={balance}
                          allocationValue={state.allocationValue}
                          allocation={state.allocation}
                          allocationTokenData={allocationTokenData}
                          leftSDT={leftSDT}
                          onAllocate={(event) => onAllocate(event)}
                          onApprove={(event) => onApprove(event)}
                          onAllocateValueChange={(event) =>
                            onAllocateValueChange(event)
                          }
                          onApplyPercentage={(percentage, value) =>
                            onApplyPercentage(percentage, value)
                          }
                        />
                        {state.errorMessage && (
                          <Message
                            descriptionText={
                              state.errorMessage.includes('404')
                                ? `${state.errorMessage} ${MessageText.Fees}`
                                : state.errorMessage
                            }
                            messageType={MessageType.Error}
                          />
                        )}
                      </Tab.Content>
                    </Tab.Container>
                  )}
              </div>
            </Card.Body>
          </Accordion.Collapse>
        )}
      </Card>
    </Accordion>
  );
};
