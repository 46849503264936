import { DataItem } from 'components';
import { isProduction } from 'helpers';
import {
  AllocationPoolItemAllocationData,
  AllocationPoolItemData,
  TokenSymbol,
} from 'models';
import React from 'react';

interface AllocationPoolItemProps {
  allocationPoolItemData: AllocationPoolItemData;
  balance: string;
  allocation: AllocationPoolItemAllocationData;
  symbol: TokenSymbol;
}

export const AllocationPoolItemDetails: React.FC<AllocationPoolItemProps> = ({
  allocationPoolItemData,
  balance,
  allocation,
  symbol,
}) => {
  return (
    <div className="staking-pool-item-details">
      {allocationPoolItemData.isActive ? (
        <DataItem label="In wallet" data={balance} symbol={symbol} />
      ) : (
        ''
      )}
      <DataItem
        label="Deposited"
        data={allocation.allocatedTokens}
        symbol={symbol}
      />
      {allocationPoolItemData.isActive ? (
        <DataItem
          label="Max. allocation"
          data={allocation.allocationLimit}
          symbol={symbol}
        />
      ) : (
        ''
      )}
      {!isProduction() ? (
        <DataItem
          label="Allocation left [DEV]"
          data={allocation.allocationLeft}
          symbol={symbol}
        />
      ) : (
        ''
      )}
      {!isProduction() ? (
        <DataItem
          label="Total Supply [DEV]"
          data={allocation.totalSupply}
          symbol={symbol}
        />
      ) : (
        ''
      )}
    </div>
  );
};
