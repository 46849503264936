import { AppChain, playnityConfig, terraExecuteContract, toWei } from 'helpers';
import { TokenData, VestingPoolData } from 'models';
import { TransactionReceipt } from 'web3-core';

import { LCDClient, MsgExecuteContract, TxInfo } from '@terra-money/terra.js';
import { ConnectedWallet } from '@terra-money/wallet-provider';

export const vestingClaim = async (
  vestingPoolData: VestingPoolData,
  connectedAddress: string,
  tokenData: TokenData,
  amountClaimable: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  terraConnectedWallet?: any,
  terra?: LCDClient
): Promise<TransactionReceipt | TxInfo> => {
  switch (process.env.REACT_APP_CHAIN) {
    case AppChain.Bsc:
    case AppChain.Ethereum:
      return await vestingClaimEvm(vestingPoolData, connectedAddress);

    case AppChain.Terra:
      return await vestingClaimTerra(
        vestingPoolData,
        connectedAddress,
        tokenData,
        amountClaimable,
        terraConnectedWallet,
        terra
      );
    // break;
    default:
      throw new Error('Unsupported Chain');
  }
};

export const vestingClaimEvm = async (
  vestingPoolData: VestingPoolData,
  connectedAddress: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<TransactionReceipt> => {
  return await vestingPoolData.vestingPoolContract.methods
    .withdrawVested()
    .send({
      from: connectedAddress,
    });
};

export const vestingClaimTerra = async (
  vestingPoolData: VestingPoolData,
  connectedAddress: string,
  tokenData: TokenData,
  amountClaimable: string,
  terraConnectedWallet: ConnectedWallet,
  terra: LCDClient
): Promise<TxInfo> => {
  const claimMsg: MsgExecuteContract = new MsgExecuteContract(
    connectedAddress,
    vestingPoolData.contractAddress,
    {
      claim: {
        amount: toWei(amountClaimable, tokenData.decimals),
      },
    },
    {
      uusd: playnityConfig.playnityFee,
    }
  );
  const tx: TxInfo = await terraExecuteContract(
    terraConnectedWallet,
    terra,
    claimMsg,
    connectedAddress
  );

  return tx;
};
