import binanceLogo from 'assets/svg/binance-logo-small.svg';

import WalletConnectProvider from '@walletconnect/web3-provider';

import { AppChain } from './config.helper';
import { isChain } from './connection.helper';
import { isProduction } from './environment.helper';

// export const getWeb3 = async (): Promise<Web3> => {
//   let web3: Web3;

//   if (window.ethereum) {
//     web3 = new Web3(window.ethereum);
//     // Request account access if needed
//     await window.ethereum.enable();
//     // Accounts now exposed
//   } else if (window.web3) {
//     // Legacy dapp browsers...
//     // Use Mist/MetaMask's provider.
//     web3 = window.web3;
//     console.log('Injected web3 detected.');
//   } else {
//     // Fallback to localhost; use dev console port by default...
//     const provider = new Web3.providers.HttpProvider('http://127.0.0.1:8545');
//     web3 = new Web3(provider);
//     console.log('No web3 instance injected, using Local web3.');
//   }

//   return web3;
// };

export const web3ModalAutoWalletConnect = true;

// TODO: Add other EVM chains if any
export const web3ModalProviderOptions = {
  'custom-binancechainwallet': {
    display: {
      logo: binanceLogo,
      name: 'Binance Wallet',
      description: 'Connect to your Binance Wallet',
    },
    package: true,
    connector: async () => {
      let provider = null;
      if (typeof window.BinanceChain !== 'undefined') {
        provider = window.BinanceChain;
        try {
          await provider.request({ method: 'eth_requestAccounts' });
        } catch (error) {
          throw new Error('User Rejected');
        }
      } else {
        alert('No Binance Wallet found');
        throw new Error('No Binance Wallet found');
      }
      return provider;
    },
  },
  walletconnect: {
    package: WalletConnectProvider, // required
    options: isChain(AppChain.Bsc)
      ? {
          // infuraId: undefined, // required
          rpc: {
            56: 'https://bsc-dataseed.binance.org/',
            ...(!isProduction() && {
              97: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
            }),
          },
          network: 'binance',
          chainId: 97,
          // qrcodeModalOptions: {
          //   mobileLinks: [
          //     "rainbow",
          //     "metamask",
          //     "argent",
          //     "trust",
          //     "imtoken",
          //     "pillar",
          //   ],
          //   desktopLinks: [
          //     "encrypted ink",
          //   ]
          // }
        }
      : {
          rpc: {
            1: 'https://api.mycryptoapi.com/eth',
            ...(!isProduction() && {
              97: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
            }),
          },
          network: 'ethereum',
          chainId: 97,
        },
  },
};

export const web3ModalOptions = {
  // network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions: web3ModalProviderOptions, // required
  // theme: "dark",
  theme: {
    background: 'rgba(7, 24, 43, .7)',
    main: 'rgb(255, 255, 255)',
    secondary: 'rgb(136, 136, 136)',
    border: 'rgba(195, 195, 195, 0.14)',
    hover: '#010b1a',
  },
};
