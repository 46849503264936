import logo from 'assets/images/playnity-app.png';
import logoMobile from 'assets/images/playnity-head.png';
import binanceLogo from 'assets/svg/binance-logo-small.svg';
import ethLogo from 'assets/svg/eth-logo.svg';
import terraLogo from 'assets/svg/terra-logo-small.svg';
import classNames from 'classnames';
import { terraTokens } from 'config';
import { AppChain, AppChainGroup, applyMask, isChain } from 'helpers';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink as Link, useLocation } from 'react-router-dom';
import { selectConnectedAddress } from 'store';

import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';
import { useWallet, Wallet } from '@starterra/starterra-tool-dapp';

import theme from './TerraWallet/mui-theme';

interface HeaderProps {
  connectWallet?: () => void;
  disconnectWallet?: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  connectWallet,
  disconnectWallet,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let terraWallet: any;
  const connectedAddress = useSelector(selectConnectedAddress);
  const location = useLocation();
  const [terraWalletTokens, setTerraWalletTokens] = useState();
  const isPexLinkActive = useMemo(
    () => () =>
      // TODO: Move to routing paths everywhere
      ['/pex', '/hall-of-fame', '/leaderboard', '/sdt-sale'].includes(
        location.pathname
      ),
    [location.pathname]
  );

  if (isChain(AppChain.Terra)) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    terraWallet = useWallet();
  }

  useEffect(() => {
    if (isChain(AppChain.Terra) && terraWallet?.network) {
      setTerraWalletTokens(terraTokens[terraWallet.network.name]);
    }
  }, [terraWallet?.network]);

  const chainLogo = (() => {
    switch (process.env.REACT_APP_CHAIN as AppChain) {
      case AppChain.Bsc:
        return binanceLogo;
      case AppChain.Terra:
        return terraLogo;
      case AppChain.Ethereum:
        return ethLogo;
    }
  })();

  return (
    <header className="header header-style-four">
      <div id="sticky-header" className="header-four-wrap sticky-menu">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="main-menu menu-style-two">
                <nav>
                  <div className="flex-container justify-center align-center">
                    <div className="logo">
                      <Link to="/">
                        <img src={logo} alt="Logo" />
                      </Link>
                    </div>
                    <div className="logo-mobile">
                      <img src={logoMobile} alt="Logo" />
                    </div>
                    <img
                      className="chain-logo"
                      src={chainLogo}
                      alt="Chain logo"
                    />
                  </div>
                  <div
                    id="mobile-menu"
                    className="navbar-wrap d-none d-lg-flex"
                  >
                    <ul className="mobile-menu-list">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/staking">Staking</Link>
                      </li>
                      {isChain(AppChain.Terra) && (
                        <li>
                          <Link to="/vesting">Vesting</Link>
                        </li>
                      )}
                      {/* TODO: Enable ethereum once pex ready - add chain to leaderboard */}
                      {!isChain(AppChain.Ethereum) ? (
                        <li>
                          <a
                            className={classNames({
                              active: isPexLinkActive(),
                            })}
                            href="#"
                          >
                            PEX
                          </a>
                          <ul className="submenu">
                            <li>
                              <Link to="/pex">Experience</Link>
                            </li>
                            <li>
                              <Link to="/leaderboard">Leaderboard</Link>
                            </li>
                            <li>
                              <Link to="/hall-of-fame">Hall Of Fame</Link>
                            </li>
                            {isChain(AppChain.Bsc) ? (
                              <li>
                                <Link to="/sdt-sale">
                                  Skynity Pre-game sale
                                </Link>
                              </li>
                            ) : (
                              ''
                            )}
                          </ul>
                        </li>
                      ) : (
                        ''
                      )}
                      {isChain(AppChain.Bsc) && (
                        <li>
                          <Link to="/governance">Governance</Link>
                        </li>
                      )}
                      <li>
                        <a
                          href="https://playnity.io/nft"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          NFT
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://wormholebridge.com/#/transfer"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Bridge
                        </a>
                      </li>
                      {/* <li className="header-search">
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#search-modal"
                        >
                          <i className="fas fa-search"></i>
                        </a>
                      </li> */}
                    </ul>
                  </div>
                  {isChain(AppChainGroup.Evm) && (
                    <div className="header-wallet-section">
                      {connectedAddress && (
                        <>
                          <div className="wallet-address">
                            {applyMask(connectedAddress)}
                          </div>
                          <i
                            className="fas fa-power-off"
                            onClick={disconnectWallet}
                          ></i>
                        </>
                      )}
                      {!connectedAddress && (
                        <div className="wallet-connect" onClick={connectWallet}>
                          Connect wallet
                        </div>
                      )}
                    </div>
                  )}
                  {isChain(AppChain.Terra) && terraWalletTokens && (
                    <ScopedCssBaseline className="terra-wallet-section">
                      <Wallet
                        tokens={terraWalletTokens}
                        readOnlyMode={false}
                        customTheme={theme}
                      />
                    </ScopedCssBaseline>
                  )}
                  {/* <div className="header-action">
                    <ul>
                      <li className="header-search">
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#search-modal"
                        >
                          <i className="fas fa-search"></i>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </nav>
              </div>
              <div className="mobile-menu"></div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
