export interface GovernancePollsResponse {
  polls: GovernancePollItem[];
}

export type GovernancePollItem = {
  id: number;
  status: GovernancePollStatus;
  title: string;
  description: string;
  question: string;
  options: GovernancePollOption[];
  voting_start: number;
  voting_end: number;
  result?: number;
  voted?: boolean;
  voted_value?: number;
};

export enum GovernancePollStatus {
  'Draft' = -1,
  'Inactive' = 0,
  'Active' = 1,
  'Finished' = 2,
  'Sealed' = 3, // Closed - counting results
}

export interface GovernancePollOption {
  value: number;
  text: string;
  percentage?: number;
}

export interface GovernanceRegisterVoteResponse {
  result: string;
  pollId: number;
  address: string;
  value: number;
}
