import { StableTokenPrices, TokenSymbol } from 'models';
import Web3 from 'web3';
import Web3Modal from 'web3modal';

import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import { LCDClient } from '@terra-money/terra.js';

export interface AppState {
  web3: Web3;
  web3Modal: Web3Modal;
  web3Provider: any;
  gasPrice: any;
  terra: LCDClient;
  connectedAddress: string;
  balance: string;
  balanceLP: string;
  balanceStablecoin: string;
  tokenPrice: string;
  LPTokenPrice: string;
  stableTokenPrices: StableTokenPrices;
  initializingFinished: boolean;
  showLoader: boolean;
  showModal: boolean;
  allocationTvl: {
    busd: string;
    ply: string;
  };
}

export const initialAppState: AppState = {
  web3: null,
  web3Modal: null,
  web3Provider: null,
  gasPrice: null,
  terra: null,
  connectedAddress: null,
  balance: null,
  balanceLP: null,
  balanceStablecoin: null,
  tokenPrice: null,
  LPTokenPrice: null,
  stableTokenPrices: null,
  initializingFinished: null,
  showLoader: true,
  showModal: true,
  allocationTvl: {
    busd: null,
    ply: null,
  },
};

export const appSlice = createSlice<
  AppState,
  SliceCaseReducers<AppState>,
  string
>({
  name: 'app',
  initialState: initialAppState,
  reducers: {
    setWeb3: (state, action: PayloadAction<Web3>) => {
      state.web3 = action.payload;
    },
    setWeb3Modal: (state, action: PayloadAction<Web3Modal>) => {
      state.web3Modal = action.payload;
    },
    setWeb3Provider: (state, action: PayloadAction<any>) => {
      state.web3Provider = action.payload;
    },
    setConnectedAddress: (state, action: PayloadAction<string>) => {
      state.connectedAddress = action.payload;
    },
    setBalance: (state, action: PayloadAction<string>) => {
      state.balance = action.payload;
    },
    setBalanceLP: (state, action: PayloadAction<string>) => {
      state.balanceLP = action.payload;
    },
    setBalanceStablecoin: (state, action: PayloadAction<string>) => {
      state.balanceStablecoin = action.payload;
    },
    setTokenPrice: (state, action: PayloadAction<string>) => {
      state.tokenPrice = action.payload;
    },
    setLPTokenPrice: (state, action: PayloadAction<string>) => {
      state.LPTokenPrice = action.payload;
    },
    setStableTokenPrices: (state, action: PayloadAction<StableTokenPrices>) => {
      state.stableTokenPrices = action.payload;
    },
    setInitializingFinished: (state, action: PayloadAction<boolean>) => {
      state.initializingFinished = action.payload;
    },
    setInitialData: (state, action: PayloadAction<AppState>) => {
      state.gasPrice = action.payload.gasPrice;
      state.web3 = action.payload.web3;
      state.terra = action.payload.terra;
      state.connectedAddress = action.payload.connectedAddress;
    },
    setShowLoader: (state, action: PayloadAction<boolean>) => {
      state.showLoader = action.payload;
    },
    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
    setAllocationTvl: (
      state,
      action: PayloadAction<{ symbol: string; totalSupply: string }>
    ) => {
      if (action.payload.symbol === TokenSymbol.PLY) {
        state.allocationTvl.ply = action.payload.totalSupply;
      } else if (action.payload.symbol === TokenSymbol.BUSD) {
        state.allocationTvl.busd = action.payload.totalSupply;
      }
    },
    resetAppState: (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = initialAppState;
    },
  },
});
