import { AppChain, isChain } from 'helpers';
import { StakingPoolData, StakingPoolType, TokenData } from 'models';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectBalance,
  selectLPBalance,
  selectLPTokenData,
  selectStablecoinBalance,
  selectStableTokenData,
  selectStakingPools,
  selectTokenData,
} from 'store';

import StakingPool from './StakingPool';

const StakingPools: React.FC = () => {
  const balance: string = useSelector(selectBalance);
  const LPBalance: string = useSelector(selectLPBalance);
  const stablecoinBalance: string = useSelector(selectStablecoinBalance);
  const tokenData: TokenData = useSelector(selectTokenData);
  const LPTokenData: TokenData = useSelector(selectLPTokenData);
  const stableTokenData: TokenData = useSelector(selectStableTokenData);
  const stakingPoolsData: StakingPoolData[] = useSelector(selectStakingPools);

  const getStakingTokenData = (stakingPoolData: StakingPoolData): TokenData => {
    switch (stakingPoolData.poolType) {
      case StakingPoolType.SA:
        return tokenData;
      case StakingPoolType.LP:
        return LPTokenData;
      case StakingPoolType.SC:
        return stableTokenData;
    }
  };

  const getPoolTokenBalance = (stakingPoolData: StakingPoolData): string => {
    switch (stakingPoolData.poolType) {
      case StakingPoolType.SA:
        return balance;

      case StakingPoolType.LP:
        return LPBalance;

      case StakingPoolType.SC:
        return stablecoinBalance;
    }
  };

  const stakingPools = stakingPoolsData?.map(
    (stakingPoolData: StakingPoolData, index: number) => {
      return (
        <StakingPool
          key={`staking-pool-component-${index}`}
          stakingPoolData={stakingPoolData}
          LPTokenData={LPTokenData}
          tokenData={tokenData}
          stablecoinTokenData={stableTokenData}
          stakingTokenData={getStakingTokenData(stakingPoolData)}
          balance={getPoolTokenBalance(stakingPoolData)}
        />
      );
    }
  );

  return (
    <div className="staking-pools">
      {isChain(AppChain.Bsc) ? (
        <>
          <div
            className="staking-pool lp-staking"
            style={{ filter: 'brightness(0.6)' }}
          >
            <div className="staking-pool-header">
              <span className="pool-type">{'// LP Staking'}</span>
              <span>PLY-BUSD</span>
              <div
                className="mt-24"
                style={{
                  border: '1px solid #495057',
                  borderRadius: '5px',
                  padding: '20px',
                  textTransform: 'none',
                  fontWeight: 'normal',
                  fontSize: 'small',
                }}
              >
                Due to the cessation of the BUSD stablecoin support by Paxos and
                Binance, we have performed a migration of the PLY/BUSD LP
                staking polls to the the PLY/USDT staking pool. The obsolete
                PLY/BUSD staking pools have been terminated, and any staked
                PLY/BUSD LP tokens have been converted to PLY/USDT LP tokens and
                restaked in the new liquid staking contract below. Any rewards
                accumulated in the terminated staking pools have been withdrawn
                directly to your wallet. For more details and updates, please
                follow our{' '}
                <a
                  className="link"
                  href="https://twitter.com/play_nity"
                  target="_blank"
                  rel="noreferrer"
                >
                  X (Twitter) profile
                </a>
                .
              </div>
            </div>
          </div>
        </>
      ) : (
        ''
      )}
      {tokenData && LPTokenData && stakingPools}
      {isChain(AppChain.Bsc) ? (
        <>
          <div
            className="staking-pool stablecoin-staking"
            style={{ filter: 'brightness(0.6)' }}
          >
            <div className="staking-pool-header">
              <span className="pool-type">
                {'// STABLECOIN STAKING - PEX BONUS'}
              </span>
              <span>BUSD</span>
              <div
                className="mt-24"
                style={{
                  border: '1px solid #495057',
                  borderRadius: '5px',
                  padding: '20px',
                  textTransform: 'none',
                  fontWeight: 'normal',
                  fontSize: 'small',
                }}
              >
                Due to the cessation of the BUSD stablecoin support by Paxos and
                Binance, the stablecoin staking pools have been terminated. Any
                staked BUSD and accumulated rewards have been withdrawn directly
                to your wallet. For more details and updates, please follow our{' '}
                <a
                  className="link"
                  href="https://twitter.com/play_nity"
                  target="_blank"
                  rel="noreferrer"
                >
                  X (Twitter) profile
                </a>
                .
              </div>
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default StakingPools;
