import BigNumber from 'bignumber.js';
import {
  BondedTokens,
  ButtonWithSpinner,
  LockedTokens,
  Message,
  PercentageButtons,
} from 'components';
import {
  MessageText,
  MessageType,
  numbersOnly,
  playnityConfig,
  StakingPoolActions,
} from 'helpers';
import {
  StakingPoolItemData,
  StakingPoolItemStakingData,
  TokenData,
} from 'models';
import React from 'react';
import { Tab } from 'react-bootstrap';

interface StakingPoolItemWithdrawTabContentProps {
  loading: boolean;
  unstakingValue: string;
  staking: StakingPoolItemStakingData;
  tokenData: TokenData;
  stakingPoolItemData: StakingPoolItemData;
  onUnstake: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onWithdraw: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    instant?: boolean
  ) => void;
  onUnstakeValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onApplyPercentage: (percentage: number, value: string, type: string) => void;
}

export const StakingPoolItemWithdrawTabContent: React.FC<
  StakingPoolItemWithdrawTabContentProps
> = ({
  loading,
  unstakingValue,
  staking,
  tokenData,
  stakingPoolItemData,
  onUnstake,
  onWithdraw,
  onUnstakeValueChange,
  onApplyPercentage,
}) => {
  const isBondedPoolType = staking?.unbondingPeriod !== undefined;
  const isLockedPoolType = !!stakingPoolItemData.lockPeriod;
  const hasBondedTokens = !!staking.bondedTokensData?.length;

  const unstakingValueBN = new BigNumber(unstakingValue);
  const stakedTokensBN = new BigNumber(staking.stakedTokens);
  const availableForWithdrawalBN = new BigNumber(
    staking.availableForWithdrawal
  );

  const inputDisabled = isLockedPoolType
    ? availableForWithdrawalBN.isEqualTo(0)
    : stakedTokensBN.isEqualTo(0);
  const percentageButtonsDisabled =
    loading || isLockedPoolType
      ? availableForWithdrawalBN.isEqualTo(0)
      : stakedTokensBN.isEqualTo(0);

  const unbondingsExceeded =
    staking.bondedTokensData?.length >= playnityConfig.maxUnbondingsPerAccount;

  const unstakeButtonDisabled =
    !+staking.stakedTokens ||
    !+unstakingValue ||
    unstakingValueBN.isGreaterThan(stakedTokensBN) ||
    unbondingsExceeded;

  const withdrawButtonDisabled =
    +staking.availableForWithdrawal == 0 ||
    +unstakingValue == 0 ||
    unstakingValueBN.isGreaterThan(availableForWithdrawalBN);

  const renderLockedTokens = () => {
    return (
      hasBondedTokens && (
        <LockedTokens
          staking={staking}
          lockedTokensData={staking.bondedTokensData}
          tokenSymbol={tokenData.symbol}
        />
      )
    );
  };

  const renderBondedTokens = () => {
    return (
      hasBondedTokens && (
        <BondedTokens
          loading={loading}
          staking={staking}
          bondedTokensData={staking.bondedTokensData}
          tokenSymbol={tokenData.symbol}
          onWithdraw={(event, instant?) => onWithdraw(event, instant)}
        />
      )
    );
  };

  const renderLockedMessage = () => {
    return hasBondedTokens ? (
      <span>
        You can only withdraw the amount for which the lock period of{' '}
        {stakingPoolItemData.lockPeriod} months from staking has elapsed
        (Available for withdrawal).
      </span>
    ) : (
      <span>You don{"'"}t have any tokens available for withdrawal. </span>
    );
  };

  const renderBondingMessage = () => {
    const hasAnyAvailableWithdrawal = !(
      +staking.totalInUnbonding + +staking.availableForWithdrawal
    );

    return (
      <div>
        {hasAnyAvailableWithdrawal && (
          <div>
            You don&apos;t have any tokens available for withdrawal. Please
            unstake your tokens first. They will be available for you after
            unbonding period or instantly, if you pay an instant withdrawal fee.
          </div>
        )}
        {unbondingsExceeded && (
          <div>
            You cannot have more active unbondings than{' '}
            {playnityConfig.maxUnbondingsPerAccount} from one address.
          </div>
        )}
      </div>
    );
  };

  const renderButton = () => {
    if (isBondedPoolType) {
      return (
        <ButtonWithSpinner
          text="Unstake"
          classes="btn-style-two"
          onClick={onUnstake}
          disabled={unstakeButtonDisabled}
          loading={loading}
        />
      );
    } else if (isLockedPoolType) {
      return (
        <ButtonWithSpinner
          text="Withdraw"
          classes="btn-style-three"
          onClick={onWithdraw}
          disabled={withdrawButtonDisabled}
          loading={loading}
        />
      );
    }
  };

  return (
    <Tab.Pane eventKey="withdraw" unmountOnExit={true} mountOnEnter={true}>
      <div className="tab-content">
        <div className="unstake-section">
          <div className="unstake-section-description">
            {isLockedPoolType && renderLockedMessage()}
            {isBondedPoolType && renderBondingMessage()}
          </div>
          {isLockedPoolType && renderLockedTokens()}
          <div className="unstake-section-actions">
            <div className="left">
              <div className="playnity-input-wrapper">
                <input
                  className="playnity-input"
                  placeholder="0.00"
                  onChange={onUnstakeValueChange}
                  value={unstakingValue}
                  onKeyPress={numbersOnly}
                  disabled={inputDisabled}
                />
              </div>
              {renderButton()}
            </div>
            <div className="right">
              <PercentageButtons
                disabled={percentageButtonsDisabled}
                variant={isLockedPoolType ? 'aquamarine' : ''}
                applyPercentage={(percentage) =>
                  onApplyPercentage(
                    percentage,
                    isLockedPoolType
                      ? staking.availableForWithdrawal
                      : staking.stakedTokens,
                    isLockedPoolType
                      ? StakingPoolActions.Withdraw
                      : StakingPoolActions.Unstake
                  )
                }
              />
            </div>
          </div>
        </div>
        {unstakingValueBN.isGreaterThan(stakedTokensBN) && (
          <Message
            messageType={MessageType.Error}
            descriptionText={MessageText.UnstakingValueNotGreaterThanStaked}
          />
        )}
        {staking?.unbondingPeriod !== undefined && renderBondedTokens()}
      </div>
    </Tab.Pane>
  );
};
