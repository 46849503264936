import { Footer, Header, MainAlert } from 'components';
import { AppChain, isChain } from 'helpers';
import React, { PropsWithChildren } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface LayoutProps {
  connectWallet: () => void;
  disconnectWallet: () => void;
}

export const Layout: React.FC<PropsWithChildren<LayoutProps>> = ({
  connectWallet,
  disconnectWallet,
  children,
}) => {
  const location = useLocation();

  return (
    <div className="app-layout">
      <Header
        connectWallet={connectWallet}
        disconnectWallet={disconnectWallet}
      />

      <div className="app-content">
        {false && location.pathname != '/sdt-sale' && (
          <MainAlert>
            <div className="container ">
              <div className="row main-alert-header">
                SkyNity pre-game sale information
              </div>
              <div className="row main-alert-body">
                You can now take part in the early pre-game sale of the&nbsp;
                <a
                  className="link"
                  href="https://skynity.io/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  SkyNity game
                </a>
                &nbsp; SDT token. Go to the&nbsp;
                {isChain(AppChain.Bsc) ? (
                  <Link className="link" to="/sdt-sale">
                    SkyNity Pre-game sale page
                  </Link>
                ) : (
                  <a
                    href="http://bsc.playnity.io/sdt-sale"
                    className="link"
                    rel="noreferrer"
                    target="_blank"
                  >
                    {' '}
                    SkyNity Pre-game sale page on BSC chain app
                  </a>
                )}
                &nbsp; and secure your allocation.
              </div>
            </div>
          </MainAlert>
        )}

        <div className="app-wrapper">
          <div className="container">
            <div className="row justify-content-center relative">
              <div className="col-xl-8 col-lg-9 app-inner">{children}</div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
