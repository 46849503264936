import { LevelItem } from 'components';
import { experienceLevels } from 'helpers';
import { ExperienceLevel, UserExperienceData } from 'models';
import React from 'react';

interface LevelItemsProps {
  userExperienceData: UserExperienceData;
  onRegisterClick: () => void;
}

export const LevelItems: React.FC<LevelItemsProps> = ({
  userExperienceData,
  onRegisterClick,
}) => {
  const levelItems = experienceLevels.map(
    (experienceLevel: ExperienceLevel, index: number) => (
      <LevelItem
        userExperienceData={userExperienceData}
        experienceLevel={experienceLevel}
        key={`experience-level-item-${index}`}
        onRegisterClick={() => onRegisterClick()}
      />
    )
  );

  return <div className="level-items">{levelItems}</div>;
};
