import { isProduction } from 'helpers/environment.helper';
import {
  AppConfig,
  PlaynityTokenName,
  StakingPoolConfig,
  StakingPoolItemName,
  StakingPoolItemType,
  StakingPoolName,
  StakingPoolSubtype,
  StakingPoolType,
  TokenSymbol,
  VestingPoolConfig,
  VestingPoolType,
} from 'models';

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Tokens } from '@starterra/starterra-tool-dapp';

import LPPairContractJSON from './contracts/LPPair.json';
import tokenLPContractJSON from './contracts/LPToken.json';
import SCPairContractJSON from './contracts/SCPair.json';
import SCTokenContract from './contracts/SCToken.json';
import stakingLPThreeMonthsContractJSON from './contracts/StakingLP3Months.json';
import stakingLPLiquidContractJSON from './contracts/StakingLPLiquid.json';
// import stakingLPSixMonthsContractJSON from './contracts/StakingLP6Months.json';
// import stakingLPTwelveMonthsContractJSON from './contracts/StakingLP12Months.json';
import stakingSALiquidContractJSON from './contracts/StakingSALiquid.json';
import stakingSABronzeContractJSON from './contracts/StakingSALiquidBronze.json';
import stakingSADiamondContractJSON from './contracts/StakingSALiquidDiamond.json';
import stakingSAGoldContractJSON from './contracts/StakingSALiquidGold.json';
import stakingSASilverContractJSON from './contracts/StakingSALiquidSilver.json';
import stakingSCBronzeContractJSON from './contracts/StakingSCLiquidBronze.json';
import stakingSCDiamondContractJSON from './contracts/StakingSCLiquidDiamond.json';
import stakingSCGoldContractJSON from './contracts/StakingSCLiquidGold.json';
import stakingSCSilverContractJSON from './contracts/StakingSCLiquidSilver.json';
import tokenContractJSON from './contracts/Token.json';
// import stakingSAThreeMonthsContractJSON from './contracts/StakingSA3Months.json';
// import stakingSASixMonthsContractJSON from './contracts/StakingSA6Months.json';
// import stakingSATwelveMonthsContractJSON from './contracts/StakingSA12Months.json';
import presaleVestingContractJSON from './contracts/VestingPresale.json';
import teamVestingContractJSON from './contracts/VestingTeam.json';
import team2VestingContractJSON from './contracts/VestingTeam2.json';

// TODO: Move to config.model / helper ( avoid circular dependency - separate MR)
export enum TerraNetworkType {
  Testnet = 'bombay-12',
  Mainnet = 'columbus-5',
}

export const avaliableTerraNetworks = {
  0: {
    walletconnectID: 2,
    name: 'mainnet',
    chainID: TerraNetworkType.Mainnet,
    lcd: 'https://lcd.terra.dev',
  },
  ...(!isProduction() && {
    1: {
      walletconnectID: 0,
      name: 'testnet',
      chainID: TerraNetworkType.Testnet,
      lcd: 'https://bombay-lcd.terra.dev',
    },
  }),
};

export const terraTokens: Record<string, Tokens> = {
  testnet: [
    {
      address:
        'bombay-12' in tokenContractJSON.networks
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (tokenContractJSON.networks as any)['bombay-12'].address
          : '',
      name: 'PLY',
      isDefault: false,
      decimal: 6,
    },
    {
      address:
        'bombay-12' in tokenLPContractJSON.networks
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (tokenLPContractJSON.networks as any)['bombay-12'].address
          : '',
      name: 'LP',
      isDefault: false,
      decimal: 6,
    },
    {
      address: 'uluna',
      name: 'LUNA',
      isDefault: false,
      decimal: 6,
    },
    {
      address: 'uusd',
      name: 'UST',
      isDefault: false,
      decimal: 6,
    },
  ],
  mainnet: [
    {
      address:
        'columbus-5' in tokenContractJSON.networks
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (tokenContractJSON.networks as any)['columbus-5'].address
          : '',
      name: 'PLY',
      isDefault: false,
      decimal: 6,
    },
    {
      address:
        'columbus-5' in tokenLPContractJSON.networks
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (tokenLPContractJSON.networks as any)['columbus-5'].address
          : '',
      name: 'LP',
      isDefault: false,
      decimal: 6,
    },
    {
      address: 'uluna',
      name: 'LUNA',
      isDefault: false,
      decimal: 6,
    },
    {
      address: 'uusd',
      name: 'UST',
      isDefault: false,
      decimal: 6,
    },
  ],
};

const LPStakingPool: StakingPoolConfig = {
  poolType: StakingPoolType.LP,
  poolDescription: 'PLY-UST',
  poolName: StakingPoolName.LP,
  poolItems: [
    {
      poolItemName: StakingPoolItemName.Liquid,
      poolItemtype: StakingPoolItemType.Bonded,
      stakingContractJSON: stakingLPLiquidContractJSON,
    },
    {
      poolItemName: StakingPoolItemName.ThreeMonths,
      poolItemtype: StakingPoolItemType.Locked,
      stakingContractJSON: stakingLPThreeMonthsContractJSON,
      lockPeriod: 3,
    },
  ],
};
const SAStakingPool: StakingPoolConfig = {
  poolType: StakingPoolType.SA,
  poolDescription: TokenSymbol.PLY,
  poolName: StakingPoolName.SA,
  poolItems: [
    {
      poolItemName: StakingPoolItemName.Liquid,
      poolItemtype: StakingPoolItemType.Bonded,
      stakingContractJSON: stakingSALiquidContractJSON,
    },
  ],
};
const PEXSAStakingPool: StakingPoolConfig = {
  poolType: StakingPoolType.SA,
  poolSubtype: StakingPoolSubtype.PEX,
  poolDescription: TokenSymbol.PLY,
  poolName: StakingPoolName.PEXSA,
  poolItems: [
    {
      poolItemName: StakingPoolItemName.LiquidPexBronze,
      poolItemtype: StakingPoolItemType.Bonded,
      stakingContractJSON: stakingSABronzeContractJSON,
      hasWhitelist: true,
    },
    {
      poolItemName: StakingPoolItemName.LiquidPexSilver,
      poolItemtype: StakingPoolItemType.Bonded,
      stakingContractJSON: stakingSASilverContractJSON,
      hasWhitelist: true,
    },
    {
      poolItemName: StakingPoolItemName.LiquidPexGold,
      poolItemtype: StakingPoolItemType.Bonded,
      stakingContractJSON: stakingSAGoldContractJSON,
      hasWhitelist: true,
    },
    {
      poolItemName: StakingPoolItemName.LiquidPexDiamond,
      poolItemtype: StakingPoolItemType.Bonded,
      stakingContractJSON: stakingSADiamondContractJSON,
      hasWhitelist: true,
    },
  ],
};
const PEXSCStakingPool: StakingPoolConfig = {
  poolType: StakingPoolType.SC,
  poolSubtype: StakingPoolSubtype.PEX,
  poolDescription: 'aUST',
  poolName: StakingPoolName.PEXSC,
  poolItems: [
    {
      poolItemName: StakingPoolItemName.LiquidPexBronze,
      poolItemtype: StakingPoolItemType.Bonded,
      stakingContractJSON: stakingSCBronzeContractJSON,
      hasWhitelist: true,
    },
    {
      poolItemName: StakingPoolItemName.LiquidPexSilver,
      poolItemtype: StakingPoolItemType.Bonded,
      stakingContractJSON: stakingSCSilverContractJSON,
      hasWhitelist: true,
    },
    {
      poolItemName: StakingPoolItemName.LiquidPexGold,
      poolItemtype: StakingPoolItemType.Bonded,
      stakingContractJSON: stakingSCGoldContractJSON,
      hasWhitelist: true,
    },
    {
      poolItemName: StakingPoolItemName.LiquidPexDiamond,
      poolItemtype: StakingPoolItemType.Bonded,
      stakingContractJSON: stakingSCDiamondContractJSON,
      hasWhitelist: true,
    },
  ],
};

const activeStakingPools: StakingPoolConfig[] = [
  LPStakingPool,
  SAStakingPool,
  PEXSAStakingPool,
  PEXSCStakingPool,
];
const activeVestingPools: VestingPoolConfig[] = [
  {
    poolType: VestingPoolType.Presale,
    vestingContractJSON: presaleVestingContractJSON,
  },
  {
    poolType: VestingPoolType.Team,
    vestingContractJSON: teamVestingContractJSON,
  },
  {
    poolType: VestingPoolType.Team2,
    vestingContractJSON: team2VestingContractJSON,
  },
];

export const terraConfig: AppConfig = {
  playnityFee: '1000000',
  maxUnbondingsPerAccount: 3,
  maxLockedStakesPerAccount: 50,
  permittedNetworks: Object.values(avaliableTerraNetworks).map(
    (terraNetwork) => terraNetwork.chainID
  ),
  tokens: [
    {
      name: PlaynityTokenName.Playnity,
      symbol: TokenSymbol.PLY,
      decimals: 6,
      tokenContractJSON: tokenContractJSON,
    },
    {
      name: PlaynityTokenName.LPPlaynity,
      symbol: TokenSymbol.LPPLY,
      decimals: 6,
      tokenContractJSON: tokenLPContractJSON,
    },
    {
      name: PlaynityTokenName.Stablecoin,
      symbol: TokenSymbol.UST,
      decimals: 6,
      tokenContractJSON: SCTokenContract,
    },
  ],
  vesting: {
    pools: activeVestingPools,
  },
  staking: {
    pools: activeStakingPools,
  },
  LPPair: {
    contractJSON: LPPairContractJSON,
  },
  stablecoinPair: {
    contractJSON: SCPairContractJSON,
  },
  additionalData: {
    tokenAddress: 'terra13awdgcx40tz5uygkgm79dytez3x87rpg4uhnvu',
    tokenChartLink:
      'https://coinhall.org/charts/terra/terra19fjaurx28dq4wgnf9fv3qg0lwldcln3jqafzm6',
    tokenLink: 'https://app.terraswap.io/swap',
    lpPairLink: 'https://app.terraswap.io/swap?to=&type=provide&from=uluna',
    evnLinkContractAddress: 'terra1gjswzpn07e662zr34h8g730pk5yahaqrf2q76g',
  },
};
