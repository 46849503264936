import bech32 from 'bech32';
import Web3 from 'web3';

export const linkTerraAddress = async (
  bscAddress: string,
  terraAddress: string,
  web3: Web3
): Promise<{
  result: string;
  bsc: string;
  terra: string;
}> => {
  const signature = await web3.eth.personal.sign(
    terraAddress,
    bscAddress,
    null
  );
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}addr-link/register`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        bsc: bscAddress,
        terra: terraAddress,
        signature,
      }),
    }
  );
  // const recoveredFrom = await web3.eth.personal.ecRecover(
  //   terraAddress,
  //   signature
  // );

  return await response.json();
};

export const checkTerraAddressLinkStatus = async (
  address: string
): Promise<{ linked: boolean }> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}addr-link/status?address=${address}`
  );

  return await response.json();
};

// TODO: Fix address validation - not working
export const isValidTerraAddress = (address: string) => {
  return new RegExp(/(terra1[a-z0-9]{38})$/, 'g').test(address);
  // advanced address validation, it verify also the bech32 checksum
  try {
    const { prefix: decodedPrefix } = bech32.bech32.decode(address); // throw error if checksum is invalid
    // verify address prefix
    return decodedPrefix === 'terra';
  } catch {
    // invalid checksum
    return false;
  }
};
