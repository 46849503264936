import { MessageType } from 'helpers';
import React, { PropsWithChildren } from 'react';

export const Message: React.FC<PropsWithChildren<{
  messageType?: MessageType;
  descriptionText?: string;
  headerText?: string;
}>> = ({ descriptionText, messageType, headerText, children }) => {
  return (
    <div className="message">
      {headerText && <div className="message-header">{headerText}</div>}
      <div className={`message-description  ${messageType}`}>
        {descriptionText} <span>{children}</span>
      </div>
    </div>
  );
};
