import { AppChain, playnityConfig } from 'helpers';
import { Dispatch } from 'react';
import { stablePairActions } from 'store';
import Web3 from 'web3';

import { Action } from '@reduxjs/toolkit';

export const getStablecoinPairContracts = (
  networkId: string,
  web3?: Web3
): ((dispatch: Dispatch<Action>) => void) => {
  switch (process.env.REACT_APP_CHAIN) {
    case AppChain.Bsc:
    case AppChain.Ethereum:
      return getStablecoinPairEvmContract();
    case AppChain.Terra:
      return getStablecoinPairTerraContract(networkId);
  }
};

const getStablecoinPairEvmContract = (): ((
  dispatch: Dispatch<Action>
) => void) => {
  return (dispatch: Dispatch<Action>) => {
    try {
      dispatch(
        stablePairActions.setStablePairData({
          pairContract: null,
          contractAddress: null,
        })
      );
    } catch (e) {
      console.log(e);
    }
  };
};

const getStablecoinPairTerraContract = (
  networkId: string
): ((dispatch: Dispatch<Action>) => void) => {
  return (dispatch: Dispatch<Action>) => {
    try {
      const contractAddress = (
        playnityConfig.stablecoinPair.contractJSON.networks as any
      )[networkId]?.address;
      dispatch(
        stablePairActions.setStablePairData({
          pairContract: null,
          contractAddress,
        })
      );
    } catch (e) {
      console.log(e);
    }
  };
};
