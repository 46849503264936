import { canAllocate, getContract, playnityConfig } from 'helpers';
import {
  AllocationPoolConfig,
  AllocationPoolData,
  AllocationPoolItemConfig,
  AllocationPoolItemData,
} from 'models';
import { Dispatch } from 'react';
import { allocationActions } from 'store';
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';

import { Action } from '@reduxjs/toolkit';

export const getAllocationPoolsData = (
  networkId: string,
  web3: Web3,
  connectedAddress: string,
  gasPrice: string
): ((dispatch: Dispatch<Action>) => void) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      const allocationPoolsData: AllocationPoolData[] = await Promise.all(
        playnityConfig.allocation?.pools.map(
          async (allocation: AllocationPoolConfig) => {
            const allocationPoolItems: AllocationPoolItemData[] =
              await Promise.all(
                allocation.poolItems.map(
                  async (
                    allocationPoolItem: AllocationPoolItemConfig
                  ): Promise<AllocationPoolItemData> => {
                    const poolItemContract: Contract = getContract(
                      web3,
                      allocationPoolItem.poolItemContractJSON,
                      networkId,
                      gasPrice
                    );

                    const addressCanAllocate = allocationPoolItem.hasWhitelist
                      ? await canAllocate(poolItemContract, connectedAddress)
                      : true;

                    return {
                      poolItemName: allocationPoolItem.poolItemName,
                      poolItemContract,
                      addressCanAllocate,
                      isActive: allocationPoolItem.isActive,
                    };
                  }
                )
              );

            return {
              poolType: allocation.poolType,
              poolName: allocation.poolName,
              poolDescription: allocation.poolDescription,
              poolItems: allocationPoolItems,
            };
          }
        )
      );

      dispatch(allocationActions.setAllocationPoolsData(allocationPoolsData));
    } catch (e) {
      console.log(e);
    }
  };
};
