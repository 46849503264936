import {
  Allocation,
  Experience,
  Governance,
  HallOfFame,
  Home,
  InfoCard,
  Leaderboard,
  LinkEvmAddress,
  Staking,
  Vesting,
} from 'components';
import { AppChain, getChainName, isChain } from 'helpers';
import { PairData, StableTokenPrices, TokenData } from 'models';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  getBalance,
  getLPTokenPrice,
  getTokenPrice,
  getTokensInLpPair,
  selectConnectedAddress,
  selectLpPairData,
  selectLPTokenData,
  selectStableTokenData,
  selectStableTokenPrices,
  selectTerra,
  selectTokenData,
  selectTokenPrice,
  selectTokensInLpPair,
  selectTotalLp,
} from 'store';

const Playnity: React.FC = () => {
  const dispatch = useDispatch();
  const tokenData: TokenData = useSelector(selectTokenData);
  const LPTokenData: TokenData = useSelector(selectLPTokenData);
  const stablecoinTokenData: TokenData = useSelector(selectStableTokenData);
  const stableTokenPrices: StableTokenPrices = useSelector(
    selectStableTokenPrices
  );
  const LPPairData: PairData = useSelector(selectLpPairData);
  const tokensInLPPair: string = useSelector(selectTokensInLpPair);
  const totalLP: string = useSelector(selectTotalLp);
  const tokenPrice: string = useSelector(selectTokenPrice);
  const connectedAddress: string = useSelector(selectConnectedAddress);
  const terra = useSelector(selectTerra);

  useEffect(() => {
    if (
      connectedAddress &&
      tokenData &&
      LPTokenData &&
      LPPairData &&
      stablecoinTokenData &&
      stableTokenPrices
    ) {
      dispatch(getBalance(tokenData, connectedAddress, terra));
      dispatch(
        getTokenPrice(
          stableTokenPrices,
          tokenData,
          stablecoinTokenData,
          LPPairData,
          terra
        )
      );
      dispatch(getTokensInLpPair(tokenData, LPTokenData, LPPairData, terra));
    }
  }, [
    tokenData,
    LPPairData,
    LPTokenData,
    connectedAddress,
    stablecoinTokenData,
    stableTokenPrices,
  ]);

  useEffect(() => {
    if (LPTokenData && connectedAddress) {
      dispatch(getBalance(LPTokenData, connectedAddress, terra));
    }
  }, [LPTokenData]);

  useEffect(() => {
    if (stablecoinTokenData && connectedAddress) {
      dispatch(getBalance(stablecoinTokenData, connectedAddress, terra));
    }
  }, [stablecoinTokenData]);

  useEffect(() => {
    if (tokenPrice && LPPairData) {
      dispatch(getLPTokenPrice(tokenPrice, tokensInLPPair, totalLP));
    }
  }, [tokenPrice, tokensInLPPair, totalLP]);

  const contractsAvailable =
    tokenData?.tokenContract?.options?.address || tokenData?.contractAddress;

  const placeholder = () => {
    if (connectedAddress) {
      if (!contractsAvailable) {
        const wrongNetworkText = `Please change network to ${getChainName(
          process.env.REACT_APP_CHAIN as AppChain
        )} Mainnet to continue your journey`;

        return (
          <InfoCard
            headerText="Wrong network selected"
            description={wrongNetworkText}
          ></InfoCard>
        );
      }
    } else {
      return (
        <InfoCard
          headerText="This is where the adventure begins...."
          description="I hope you’re ready to go my friend. Prepare your wallet and let your funds start the journey!"
        ></InfoCard>
      );
    }
  };

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/staking" element={placeholder() || <Staking />} />
      {isChain(AppChain.Terra) && (
        <Route path="/vesting" element={placeholder() || <Vesting />} />
      )}
      {isChain(AppChain.Bsc) && (
        <Route path="/sdt-sale" element={placeholder() || <Allocation />} />
      )}
      {!isChain(AppChain.Ethereum) && (
        <>
          <Route
            path="/leaderboard"
            element={placeholder() || <Leaderboard />}
          />
          <Route path="/pex" element={placeholder() || <Experience />} />
          <Route
            path="/hall-of-fame"
            element={placeholder() || <HallOfFame />}
          />
        </>
      )}
      {/* {isChain(AppChain.Bsc) && (
        <Route path="/link" element={placeholder() || <LinkTerraAddress />} />
      )} */}
      {isChain(AppChain.Terra) && (
        <Route
          path="/link-evm-address"
          element={placeholder() || <LinkEvmAddress />}
        />
      )}
      {isChain(AppChain.Bsc) && (
        <Route path="/governance" element={placeholder() || <Governance />} />
      )}

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>

    //   <InfoCard
    //     headerText="Site under maintenance"
    //     description="BSC App will return shortly "
    //   >
    //     <div className="maintenance-button-wrapper">
    //       <a
    //         className="btn btn-style-two home-header-btn maintenance-button"
    //         href="https://terra.playnity.io/"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         Go to TERRA App
    //       </a>
    //     </div>
    //   </InfoCard>
  );
};

export default Playnity;
