import { PairData } from 'models';
import { State } from 'store';
import { StablePairState } from 'store/reducers';

import { createSelector } from '@reduxjs/toolkit';

const selectStablePair = (state: State): StablePairState => state.stablePair;

export const selectStablePairData = createSelector(
  [selectStablePair],
  (stablePair: StablePairState): PairData => stablePair?.stablePairData
);
