import { GovernancePollItem } from 'models';

import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';

export interface GovernanceState {
  polls: GovernancePollItem[];
}

export const initialGovernanceState: GovernanceState = {
  polls: null,
};

export const governanceSlice = createSlice<
  GovernanceState,
  SliceCaseReducers<GovernanceState>,
  string
>({
  name: 'governance',
  initialState: initialGovernanceState,
  reducers: {
    setGovernancePolls: (
      state,
      action: PayloadAction<GovernancePollItem[]>
    ) => {
      state.polls = action.payload;
    },
    resetGovernanceState: (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = initialGovernanceState;
    },
  },
});
