import experienceBadge from 'assets/images/experience/header-image.svg';
import {
  DataItem,
  ExperienceInfoModal,
  LevelItems,
  SectionDetails,
  SectionLoader,
} from 'components';
import { experienceLevels } from 'helpers';
import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserExperienceData,
  registerAddress,
  selectConnectedAddress,
  selectUserExperienceData,
} from 'store';

import { ExperienceCircularProgressBar } from './';

export const Experience: React.FC = () => {
  const dispatch = useDispatch();
  const userExperienceData = useSelector(selectUserExperienceData);
  const connectedAddress = useSelector(selectConnectedAddress);
  // const [loading, setLoading] = useState(false);
  // const [errorMessage, setErrorMessage] = useState('');
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (connectedAddress) {
      dispatch(getUserExperienceData(connectedAddress));
    }
  }, [connectedAddress]);

  useEffect(() => {
    if (userExperienceData) {
      const levelStartingXp = experienceLevels.find(
        (el) => el.level === userExperienceData.curLevel
      )?.minXp;
      const levelEndingXp = experienceLevels.find(
        (el) => el.level === userExperienceData.curLevel + 1
      )?.minXp;

      if (levelStartingXp && levelEndingXp) {
        const percentage =
          ((userExperienceData.curExp - levelStartingXp) /
            (levelEndingXp - levelStartingXp)) *
          100;

        setTimeout(() => setPercentage(percentage), 100);
      }
    }
  }, [userExperienceData]);

  const onRegister = () => {
    try {
      dispatch(registerAddress(connectedAddress));
    } catch (e) {
      console.log(e);
    }
  };

  const currentExperience = () => {
    if (!userExperienceData) {
      return;
    }

    return (
      <>
        {userExperienceData.curExp} PEX{' '}
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-added-exp">
              {userExperienceData.addedExp} PEX added in the last snapshot
            </Tooltip>
          }
        >
          <i className="far fa-question-circle pointer"></i>
        </OverlayTrigger>
      </>
    );
  };

  return (
    <div className="experience" style={{ fontFamily: 'Roboto' }}>
      <SectionDetails title="Experience"></SectionDetails>

      {userExperienceData ? (
        <>
          <div className="experience-header">
            <div className="experience-progress">
              <div className="gray font-small text-center mb-8">
                Your experience level
              </div>
              <div className="experience-progress-container">
                <ExperienceCircularProgressBar
                  percentage={percentage}
                  userExperienceData={userExperienceData}
                />
              </div>
            </div>

            <div className="experience-info">
              <div className="experience-info-item">
                <img
                  className="experience-info-item-image"
                  src={experienceBadge}
                />
                <DataItem
                  label="Current experience:"
                  classes="experience-info-item-data"
                >
                  {currentExperience()}
                </DataItem>
              </div>
              <div className="experience-info-item">
                <img
                  className="experience-info-item-image"
                  src={experienceBadge}
                />
                <DataItem
                  label="Experience to next level:"
                  data={`${
                    userExperienceData.expToNextLevel?.toString() ?? '-'
                  } PEX`}
                  classes="experience-info-item-data"
                />
              </div>
            </div>
          </div>

          <div className="experience-description text-center">
            Pex points (PlayNity Experience points) can be gathered everyday by
            your possessed LPs, $PLY, leaderboard place, hodler time bonus and
            occasionally announced tasks.
          </div>

          <div className="experience-links">
            <ExperienceInfoModal />
            <a
              className="link"
              href="https://www.youtube.com/watch?v=fUPqlFrLTsQ"
              rel="noopener noreferrer"
              target="_blank"
            >
              Video tutorial
            </a>
          </div>

          <LevelItems
            userExperienceData={userExperienceData}
            onRegisterClick={() => onRegister()}
          />
        </>
      ) : (
        <SectionLoader />
      )}
    </div>
  );
};
