import { GovernanceRegisterVoteResponse } from 'models';
import Web3 from 'web3';

export const registerVote = async (
  address: string,
  questionId: number,
  answerId: number,
  web3: Web3
): Promise<GovernanceRegisterVoteResponse> => {
  const signature = await web3.eth.personal.sign(
    `${questionId}:${answerId}`,
    address,
    null
  );
  const response: Response = await fetch(
    `${process.env.REACT_APP_API_URL}dao/register-vote`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        poll: questionId,
        address,
        value: answerId,
        signature,
      }),
    }
  );

  return response.json();
};
