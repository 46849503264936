import { SectionDetails, SectionLoader } from 'components';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getHallOfFameItems, selectHallOfFameItems } from 'store';

import HallOfFameItems from './HallOfFameItems';

export const HallOfFame: React.FC = () => {
  const dispatch = useDispatch();
  const hallOfFameItems = useSelector(selectHallOfFameItems);

  useEffect(() => {
    dispatch(getHallOfFameItems());
  }, []);

  return (
    <div className="hall-of-fame">
      <SectionDetails title="Hall Of Fame">
        Wow! After collecting their PEX points, our{' '}
        <Link className="link" to="/leaderboard">
          loyal stakers
        </Link>{' '}
        have reached level 10. If you are not sure you&apos;ve made it, check
        the list below. Congrats! You have no idea what waits for you next!
        {/* <div>
          If you see yourself on the list, but did&apos;t do so yet, please{' '}
          <a
            className="link"
            href="https://playnity.io/contact/"
            rel="noopener noreferrer"
            target="_blank"
          >
            contact us
          </a>{' '}
          for your individual NFT
        </div> */}
      </SectionDetails>

      {hallOfFameItems?.length ? (
        <HallOfFameItems hallOfFameItems={hallOfFameItems} />
      ) : (
        <SectionLoader />
      )}
    </div>
  );
};
