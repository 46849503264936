import { LeaderboardAddress } from 'models';

import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';

export interface LeaderboardState {
  addresses: LeaderboardAddress[];
  currentAddressData: LeaderboardAddress;
}

export const initialLeaderboardState: LeaderboardState = {
  addresses: null,
  currentAddressData: null,
};

export const leaderboardSlice = createSlice<
  LeaderboardState,
  SliceCaseReducers<LeaderboardState>,
  string
>({
  name: 'leaderboard',
  initialState: initialLeaderboardState,
  reducers: {
    setLeaderboardAddresses: (
      state,
      action: PayloadAction<LeaderboardAddress[]>
    ) => {
      state.addresses = action.payload;
    },
    setLeaderboardCurrentAddressData: (
      state,
      action: PayloadAction<LeaderboardAddress>
    ) => {
      state.currentAddressData = action.payload;
    },
  },
});
