import { AllocationPoolData } from 'models';
import { State } from 'store';
import { AllocationState } from 'store/reducers';

import { createSelector } from '@reduxjs/toolkit';

const selectAllocationState = (state: State): AllocationState =>
  state.allocation;

export const selectAllocationPools = createSelector(
  [selectAllocationState],
  (allocationState: AllocationState): AllocationPoolData[] =>
    allocationState.pools
);
