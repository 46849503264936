import { DataItem, SectionDetails } from 'components';
import { parseCurrency, playnityConfig } from 'helpers';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectBalance,
  selectLPBalance,
  selectLPTokenData,
  selectLPTokenPrice,
  selectStableTokenPrices,
  selectTokenData,
  selectTokenPrice,
} from 'store';

import StakingPools from './StakingPools';

export const Staking: React.FC = () => {
  const balance: string = useSelector(selectBalance);
  const LPBalance: string = useSelector(selectLPBalance);
  const tokenData = useSelector(selectTokenData);
  const LPTokenData = useSelector(selectLPTokenData);
  const tokenPrice = useSelector(selectTokenPrice);
  const LPTokenPrice = useSelector(selectLPTokenPrice);
  const stableTokenPrices = useSelector(selectStableTokenPrices);
  const description =
    'Add tokens to our farm. Approve, deposit, withdraw and claim rewards. Select one of options presented below.';
  const title = 'Staking';

  return (
    <div className="staking">
      <SectionDetails description={description} title={title}></SectionDetails>
      <div className="flex-container staking-tokens">
        <div className="text-center staking-token-group">
          <div className="flex-container align-center staking-token-group-data">
            <DataItem
              label="PLY balance"
              data={balance}
              symbol={tokenData.symbol}
            ></DataItem>
            <DataItem label="PLY price">
              {tokenPrice && (
                <span>
                  {parseCurrency(
                    tokenPrice,
                    parseFloat(tokenPrice) < 10 ? 4 : undefined
                  )}
                </span>
              )}
              <a
                href={playnityConfig.additionalData.tokenChartLink}
                className="link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-chart-line link-icon-right"></i>
              </a>
            </DataItem>
          </div>
          <a
            href={playnityConfig.additionalData.tokenLink}
            className="link text-center"
            target="_blank"
            rel="noreferrer noopener"
          >
            Get {tokenData.symbol} Tokens
            <i
              className="fa fa-external-link-alt link-icon-right"
              aria-hidden="true"
            ></i>
          </a>
        </div>
        <div className="text-center staking-token-group">
          <div className="flex-container align-center staking-token-group-data">
            <DataItem
              label="LP balance"
              data={LPBalance}
              symbol={LPTokenData.symbol}
            ></DataItem>
            <DataItem
              label="LP token value"
              data={
                LPTokenPrice &&
                parseCurrency(
                  LPTokenPrice,
                  parseFloat(LPTokenPrice) < 10 ? 4 : undefined
                )
              }
            ></DataItem>
          </div>
          <a
            href={playnityConfig.additionalData.lpPairLink}
            className="link text-center"
            target="_blank"
            rel="noreferrer noopener"
          >
            Get {LPTokenData.symbol} Tokens
            <i
              className="fa fa-external-link-alt link-icon-right"
              aria-hidden="true"
            ></i>
          </a>
        </div>
      </div>
      {stableTokenPrices ? <StakingPools /> : ''}
    </div>
  );
};
