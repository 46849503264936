import { InfoCard } from 'components';
import { VestingPoolData } from 'models';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectLpPairData,
  selectLPTokenData,
  selectStableTokenData,
  selectTokenData,
  selectVestingPools,
} from 'store';

import VestingPool from './VestingPool';

const VestingPools: React.FC = () => {
  const vestingPoolsData: VestingPoolData[] = useSelector(selectVestingPools);
  const tokenData = useSelector(selectTokenData);
  const LPTokenData = useSelector(selectLPTokenData);
  const stableTokenData = useSelector(selectStableTokenData);
  const LPPairData = useSelector(selectLpPairData);
  const vestingPools = vestingPoolsData?.map(
    (vestingPoolData: VestingPoolData, index: number) => {
      return (
        <VestingPool
          key={`vesting-pool-compoonent-${index}`}
          tokenData={tokenData}
          LPTokenData={LPTokenData}
          stableTokenData={stableTokenData}
          LPPairData={LPPairData}
          vestingPoolData={vestingPoolData}
        />
      );
    }
  );

  return vestingPools?.length ? (
    <div className="vesting-pools">{vestingPools}</div>
  ) : (
    <InfoCard
      headerText="Vesting unavailable"
      description="This section does not seem to be applicable to your wallet address. Please change your wallet or go to different section"
    />
  );
};

export default VestingPools;
