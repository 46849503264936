import { GovernancePollItem, GovernancePollsResponse } from 'models';
import { Dispatch } from 'react';
import { governanceActions } from 'store';

import { Action } from '@reduxjs/toolkit';

const tempPollsMock: GovernancePollItem[] = [
  // FINISHED
  {
    id: 1,
    status: 3,
    title: 'Burning of $PLY tokens',
    description:
      'We propose to burn some of the remaining $PLY tokens originally scheduled for future distribution.<br>\n<br>\nWithin the specified period you can sign your choice with your wallet to participate in voting. Voting power will be calculated based on all your $PLY tokens staked in any staking pool available within our app according to the snapshot taken on 16.03.2023, with the consideration of bonus multiplier resulting from the possession of the <a class="link" href="https://playnity.io/nft/" target="_blank" rel="noreferrer noopener">Playnity Genesis NFTs</a>.',
    question: 'How much of the undistributed $PLY tokens do you opt to burn?',
    voting_start: 1677801600,
    voting_end: 1679011199,
    options: [
      {
        value: 0,
        text: '22.5M $PLY - 15% of the total supply',
        percentage: 93.65547,
      },
      {
        value: 1,
        text: '15M $PLY - 10% of the total supply',
        percentage: 5.008195,
      },
      {
        value: 2,
        text: '7.5M $PLY - 5% of the total supply',
        percentage: 1.318595,
      },
      { value: 3, text: 'none', percentage: 0.01774 },
    ],
    result: 0,
    voted: false,
    voted_value: null,
  },
  // NOT FINISHED
  {
    id: 2,
    status: 1,
    title: 'Burning of $PLY tokens',
    description: 'We propose 2',
    question: 'How much 2',
    voting_start: 1677801600,
    voting_end: 1710789770,
    options: [
      {
        value: 0,
        text: 'something',
      },
      {
        value: 1,
        text: 'something else',
      },
      {
        value: 2,
        text: 'something very else',
      },
      { value: 3, text: 'nothing' },
    ],
    voted: true,
    voted_value: 1,
  },
];

export const getGovernancePolls = (
  connectedAddress: string
): ((dispatch: Dispatch<Action>) => void) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      const response: Response = await fetch(
        `${process.env.REACT_APP_API_URL}dao/polls?address=${connectedAddress}`
      );
      const { polls }: GovernancePollsResponse = await response.json();

      dispatch(governanceActions.setGovernancePolls(polls));
    } catch (e) {
      console.log(e);
    }
  };
};
