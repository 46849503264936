import React, { PropsWithChildren } from 'react';

interface SectionHeaderProps {
  title: string;
  description?: string;
}

export const SectionDetails: React.FC<
  PropsWithChildren<SectionHeaderProps>
> = ({ description, title, children }) => {
  const classes = `section-details ${
    children || description ? 'with-description' : ''
  }`;

  return (
    <div className={classes}>
      <h2 className="section-details-header">
        <div className="section-details-header-text">
          Playnity <span>{title}</span>
        </div>
      </h2>

      <div className="section-details-description">
        {description || children}
      </div>
    </div>
  );
};
