import item from 'assets/images/hall-of-fame/hall-of-fame-item.svg';
import { applyMask } from 'helpers';
import { HallOfFameRecord } from 'models';
import React from 'react';

export interface HallOfFameItemProps {
  hallOfFameItem: HallOfFameRecord;
}

const HallOfFameItem: React.FC<HallOfFameItemProps> = ({ hallOfFameItem }) => {
  return (
    <div className="hall-of-fame-item">
      <img className="hall-of-fame-item-image" src={item} />
      <div className="hall-of-fame-item-address">
        {applyMask(hallOfFameItem.address)}
      </div>
      <div className="hall-of-fame-item-exp">
        PEX gathered: {hallOfFameItem.curExp}
      </div>
    </div>
  );
};

export default HallOfFameItem;
