import { PairData } from 'models';

import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';

export interface StablePairState {
  stablePairData: PairData;
}

export const initialStablePairState: StablePairState = {
  stablePairData: null,
};

export const stablePairSlice = createSlice<
  StablePairState,
  SliceCaseReducers<StablePairState>,
  string
>({
  name: 'stablePair',
  initialState: initialStablePairState,
  reducers: {
    setStablePairData: (state, action: PayloadAction<PairData>) => {
      state.stablePairData = action.payload;
    },
  },
});
