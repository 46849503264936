import {
  AppChain,
  getContract,
  isInVestingPool,
  playnityConfig,
} from 'helpers';
import { VestingPoolConfig, VestingPoolData } from 'models';
import { Dispatch } from 'react';
import { vestingActions } from 'store';
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';

import { Action } from '@reduxjs/toolkit';
import { LCDClient } from '@terra-money/terra.js';

export const getVestingPoolsData = (
  networkId: string,
  connectedAddress: string,
  web3?: Web3,
  terra?: LCDClient,
  gasPrice?: string
): ((dispatch: Dispatch<Action>) => void) => {
  switch (process.env.REACT_APP_CHAIN) {
    case AppChain.Bsc:
    case AppChain.Ethereum:
      return getEvmVestingPoolsData(
        networkId,
        connectedAddress,
        web3,
        gasPrice
      );
    case AppChain.Terra:
      return getTerraVestingPoolsData(networkId, connectedAddress, terra);
  }
};

const getEvmVestingPoolsData = (
  networkId: string,
  connectedAddress: string,
  web3: Web3,
  gasPrice: string
): ((dispatch: Dispatch<Action>) => void) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      let vestingPoolsData: VestingPoolData[] =
        playnityConfig.vesting.pools.map((vestingPool: VestingPoolConfig) => {
          const vestingPoolContractInstance: Contract = getContract(
            web3,
            vestingPool.vestingContractJSON,
            networkId,
            gasPrice
          );

          return {
            poolType: vestingPool.poolType,
            vestingPoolContract: vestingPoolContractInstance,
          };
        });

      const asyncFilter = async (arr: any[]) =>
        arr.reduce(
          async (acc: VestingPoolData[], vestingPoolData: VestingPoolData) => {
            const inVestingPool = await isInVestingPool(
              connectedAddress,
              vestingPoolData.vestingPoolContract
            );

            if (inVestingPool) {
              acc.push(vestingPoolData);
            }

            return acc;
          },
          []
        );

      vestingPoolsData = await asyncFilter(vestingPoolsData);

      dispatch(vestingActions.setVestingPoolsData(vestingPoolsData));
    } catch (e) {
      console.log(e);
    }
  };
};

const getTerraVestingPoolsData = (
  networkId: string,
  connectedAddress: string,
  terra: LCDClient
): ((dispatch: Dispatch<Action>) => void) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      let vestingPoolsData: VestingPoolData[] =
        playnityConfig.vesting.pools.map((vestingPool: VestingPoolConfig) => {
          const contractAddress = (
            vestingPool.vestingContractJSON.networks as any
          )[networkId]?.address;

          return {
            poolType: vestingPool.poolType,
            vestingPoolContract: null,
            contractAddress,
          };
        });

      const asyncFilter = async (arr: any[]) =>
        arr.reduce(
          async (acc: VestingPoolData[], vestingPoolData: VestingPoolData) =>
            (await isInVestingPool(
              connectedAddress,
              null,
              vestingPoolData.contractAddress,
              terra
            ))
              ? [...(await acc), vestingPoolData]
              : acc,
          []
        );

      vestingPoolsData = await asyncFilter(vestingPoolsData);

      dispatch(vestingActions.setVestingPoolsData(vestingPoolsData));
    } catch (e) {
      console.log(e);
    }
  };
};
