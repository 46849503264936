import { PairData } from 'models';

import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';

export interface LpPairState {
  lpPairData: PairData;
  tokensInLpPair: string;
  totalLP: string;
}

export const initialLpPairState: LpPairState = {
  lpPairData: null,
  tokensInLpPair: null,
  totalLP: null,
};

export const lpPairSlice = createSlice<
  LpPairState,
  SliceCaseReducers<LpPairState>,
  string
>({
  name: 'lpPair',
  initialState: initialLpPairState,
  reducers: {
    setLPPairData: (state, action: PayloadAction<PairData>) => {
      state.lpPairData = action.payload;
    },
    setTokensInLpPair: (state, action: PayloadAction<string>) => {
      state.tokensInLpPair = action.payload;
    },
    setTotalLp: (state, action: PayloadAction<string>) => {
      state.totalLP = action.payload;
    },
  },
});
