import { LeaderboardAddress } from 'models';
import { LeaderboardState, State } from 'store';

import { createSelector } from '@reduxjs/toolkit';

const selectLeaderboard = (state: State): LeaderboardState => state.leaderboard;

export const selectLeaderboardAddresses = createSelector(
  [selectLeaderboard],
  (leaderboardState: LeaderboardState): LeaderboardAddress[] =>
    leaderboardState?.addresses
);

export const selectLeaderboardCurrentAddressData = createSelector(
  [selectLeaderboard],
  (leaderboardState: LeaderboardState): LeaderboardAddress =>
    leaderboardState?.currentAddressData
);
