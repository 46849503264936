import { AllocationPoolData, AllocationPoolItemData, TokenData } from 'models';
import React from 'react';
import { Accordion } from 'react-bootstrap';

import { AllocationPoolItem } from './AllocationPoolItem';

interface AllocationPoolProps {
  balance: string;
  allocationTokenData: TokenData;
  tokenData: TokenData;
  stablecoinTokenData: TokenData;
  allocationPoolData: AllocationPoolData;
  leftSDT: string;
}

const AllocationPool: React.FC<AllocationPoolProps> = ({
  balance,
  allocationTokenData,
  tokenData,
  stablecoinTokenData,
  allocationPoolData,
  leftSDT,
}) => {
  const allocationPoolClassName = `staking-pool ${allocationPoolData.poolType
    .toLocaleLowerCase()
    .replaceAll(' ', '-')}`;
  const allocationPoolItems = (
    <Accordion>
      {allocationPoolData.poolItems?.map(
        (allocationPoolItemData: AllocationPoolItemData, index: number) => (
          <AllocationPoolItem
            key={`staking-pool-item-component-${index}`}
            index={index}
            balance={balance}
            allocationPoolData={allocationPoolData}
            allocationPoolItemData={allocationPoolItemData}
            allocationTokenData={allocationTokenData}
            leftSDT={leftSDT}
            tokenData={tokenData}
            stablecoinTokenData={stablecoinTokenData}
          />
        )
      )}
    </Accordion>
  );

  return (
    <div className={allocationPoolClassName}>
      {/* <div className="staking-pool-header">
        <span className="pool-type">
          {'//'} {allocationPoolData.poolName}
        </span>
        {allocationPoolData.poolDescription}
      </div> */}
      {allocationPoolItems}
    </div>
  );
};

export default AllocationPool;
