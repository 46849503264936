import { AppChain, terraExecuteContract, toWei } from 'helpers';
import { StakingPoolItemData, TokenData } from 'models';

import { LCDClient, MsgExecuteContract, TxInfo } from '@terra-money/terra.js';
import { ConnectedWallet } from '@terra-money/wallet-provider';

export const postStake = async (
  stakingPoolItemData: StakingPoolItemData,
  tokenData: TokenData,
  stakingValue: string,
  decimals: number,
  connectedAddress: string,
  terraConnectedWallet: ConnectedWallet,
  terra: LCDClient
) => {
  const stakingValueWei = toWei(stakingValue, decimals);

  switch (process.env.REACT_APP_CHAIN) {
    case AppChain.Bsc:
    case AppChain.Ethereum:
      return await postEvmStake(
        stakingPoolItemData,
        stakingValueWei,
        connectedAddress
      );

    case AppChain.Terra:
      return await postTerraStake(
        stakingPoolItemData,
        tokenData,
        stakingValueWei,
        connectedAddress,
        terraConnectedWallet,
        terra
      );

    default:
      throw new Error('Unsupported Chain');
  }
};

const postEvmStake = async (
  stakingPoolItemData: StakingPoolItemData,
  stakingValueWei: string,
  connectedAddress: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  return await stakingPoolItemData.stakingPoolItemContract.methods
    .stake(stakingValueWei)
    .send({
      from: connectedAddress,
    });
};

const postTerraStake = async (
  stakingPoolItemData: StakingPoolItemData,
  tokenData: TokenData,
  stakingValueWei: string,
  connectedAddress: string,
  terraConnectedWallet: ConnectedWallet,
  terra: LCDClient
): Promise<TxInfo> => {
  const stakeMsg: MsgExecuteContract = new MsgExecuteContract(
    connectedAddress,
    tokenData.contractAddress,
    {
      send: {
        contract: stakingPoolItemData.contractAddress,
        amount: stakingValueWei,
        msg: 'eyJib25kIjp7fX0=',
      },
    }
  );
  const tx: TxInfo = await terraExecuteContract(
    terraConnectedWallet,
    terra,
    stakeMsg,
    connectedAddress
  );
  return tx;
};
