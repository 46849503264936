import { GovernancePollItem } from 'models';
import { GovernanceState, State } from 'store';

import { createSelector } from '@reduxjs/toolkit';

const selectGovernanceState = (state: State): GovernanceState =>
  state.governance;

export const selectGovernancePolls = createSelector(
  [selectGovernanceState],
  (governanceState: GovernanceState): GovernancePollItem[] =>
    governanceState.polls
);
